import { z } from 'zod';

export const NotificationSchema = z.object({
  notification_id: z.string(),
  type: z.string(),
  read: z.boolean(),
  data: z.object({
    contacted_at: z.string(),
    email_subject: z.string(),
    employer_email: z.string(),
    employer_name: z.string(),
    job_name: z.string(),
    job_skills: z.array(z.string()),
    job_teachable_skills: z.array(z.string()),
    markdown_html: z.string(),
  }),
});

export const GetNotificationsRequestSchema = z.object({
  limit: z.number(),
  offset: z.number(),
  read: z.boolean(),
});

export const GetNotificationsResponseSchema = z.object({
  notifications: z.array(NotificationSchema),
  next_offset: z.string().nullable(),
  total: z.number(),
});

export const UpdateNotificationRequestSchema = z.object({
  read: z.boolean(),
});

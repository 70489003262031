import { forwardRef } from 'react';
import { SnackbarContent } from 'notistack';
import Card from '@mui/material/Card';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import cssVars from 'styles/vars.module.scss';
import PropTypes from 'prop-types';

const CustomNotification = forwardRef(({ children, check = false, color }, ref) => {
  const locality = process.env.REACT_APP_LOCALITY;

  return (
    <SnackbarContent
      ref={ref}
      style={{
        '@media (minWidth:600px)': {
          minWidth: '324px !important',
        },
      }}
    >
      <Card
        sx={{
          backgroundColor: locality === 'nj' || color === 'primary' ? cssVars.primaryColorMain : '#38853C',
          width: '100%',
          padding: '16px 22px',
          color: 'white',
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
          border: 'solid 1px #cdcdcd',
        }}
      >
        {check ? <CheckCircleIcon fontSize="small" style={{ marginRight: 6, position: 'relative', left: -1 }} /> : null}
        <div>{children}</div>
      </Card>
    </SnackbarContent>
  );
});

CustomNotification.propTypes = {
  children: PropTypes.node,
  check: PropTypes.bool,
  color: PropTypes.string,
};

CustomNotification.displayName = 'CustomNotification';

export default CustomNotification;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import 'normalize.css';
import 'styles/global.scss';
import 'styles/chat.scss';
import IntlApp from './IntlApp';
import store from './app/store';
import { Provider as ReduxProvider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from 'components/ErrorBoundary';

const rootElement = document.getElementById('root');

const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ?? '';

const app = (
  <React.StrictMode>
    <ErrorBoundary>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script>
            {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');
          `}
          </script>
        </Helmet>

        {/* Google Tag Manager NoScript */}
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
        </noscript>

        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          redirectUri={window.location.origin}
          cacheLocation="localstorage"
        >
          <ReduxProvider store={store}>
            <SnackbarProvider>
              <IntlApp />
            </SnackbarProvider>
          </ReduxProvider>
        </Auth0Provider>
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(app, rootElement);
} else {
  ReactDOM.render(app, rootElement);
}

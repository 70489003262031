import { AwsRum } from 'aws-rum-web';
import React from 'react';

let awsRum = null;

function initializeAwsRum() {
  if (!process.env.REACT_APP_CLOUDWATCH_RUM_APPLICATION_ID || process.env.NODE_ENV !== 'production') return;

  try {
    const config = {
      sessionSampleRate: 1,
      identityPoolId: process.env.REACT_APP_CLOUDWATCH_RUM_IDENTITY_POOL_ID,
      endpoint: `https://dataplane.rum.${process.env.REACT_APP_REGION}.amazonaws.com`,
      telemetries: ['http', 'errors', 'performance'],
      allowCookies: true,
      enableXRay: false,
    };

    const APPLICATION_ID = process.env.REACT_APP_CLOUDWATCH_RUM_APPLICATION_ID;
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = process.env.REACT_APP_REGION;

    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}

initializeAwsRum();

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (awsRum) {
      // Report the error to CloudWatch RUM
      awsRum.recordError({
        message: error.message,
        stack: error.stack,
        componentStack: errorInfo.componentStack,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;

import { useState } from 'react';
import { format } from 'date-fns';
import { z } from 'zod';
import { NotificationSchema } from 'lib/schemas/notifications';

/* i18n */
import { useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Card, CardContent, CardHeader, MenuItem, Typography } from '@mui/material';
import { SnackbarContent } from 'notistack';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { IconButton, Divider, Box, Tooltip, Badge, Menu, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { grey } from '@mui/material/colors';
import JobInvitationDialog from 'components/JobInvitationDialog';
import { JobInvitationsEmptyState } from 'components/layout/Header';

import useJobInvitations from 'lib/hooks/useJobInvitations';
import { useReadNotification } from 'lib/hooks/notifications';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export function monthDay(str: string) {
  const theDate = new Date(`${str}T00:00:00`);
  return `${theDate.getMonth() + 1}/${theDate.getDate()}/${theDate.getFullYear().toString().slice(2)}`;
}

export default function JobInvitations({ enabled }: { enabled: boolean }) {
  const [invitationsAnchorEl, setInvitationsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [markdownContent, setMarkdownContent] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const open = Boolean(invitationsAnchorEl);

  const jobInvitations = useJobInvitations(enabled);

  const intl = useIntl();
  const total = jobInvitations?.data?.notifications?.length;

  const handleNotificationsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInvitationsAnchorEl(event.currentTarget);
  };
  const handleInvitationsClose = () => {
    setInvitationsAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={intl.formatMessage({ id: 'layout.menu.jobInvitations' })}>
        <IconButton
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            handleNotificationsClick(e);
          }}
          size="small"
          sx={{ position: 'relative', top: -1, mr: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Badge badgeContent={total} color="error" id="to-do-link">
            <HandshakeIcon sx={{ width: 32, height: 32, color: 'white', position: 'relative', right: -2, top: 2 }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={invitationsAnchorEl}
        id="account-menu"
        open={open}
        onClose={handleInvitationsClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflowY: 'auto',
              maxHeight: '50vh',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              width: { xs: 'auto', md: 290 },
              zIndex: (theme) => theme.zIndex.modal + 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ mx: 3, my: 2 }}>
          <Typography variant="h4" sx={{ fontSize: 18 }}>
            {intl.formatMessage({ id: 'jobInvitations.title' })}
          </Typography>
        </Box>
        <Divider />

        {total === 0 ? (
          <Box py={1} px={3}>
            <JobInvitationsEmptyState linkClickHandler={handleInvitationsClose} />
          </Box>
        ) : null}
        {(total ?? 0) > 0 ? <Divider /> : null}
        {jobInvitations?.data?.notifications
          ?.filter((inv) => inv.type === 'readyhire:contact:v1')
          .sort((a, b) => new Date(b.data.contacted_at).getTime() - new Date(a.data.contacted_at).getTime())
          .map((jobInvitation, i: number, fullArray: object[]) => (
            <MenuItem
              key={jobInvitation.notification_id}
              sx={{
                py: 3,
                whiteSpace: 'normal',
                borderBottom: (theme) =>
                  i !== fullArray.length - 1 ? `solid 1px ${theme.palette.grey['300']}` : 'none',
              }}
              disableRipple
            >
              <JobInvitationItem
                jobInvitation={jobInvitation}
                setMarkdownContent={setMarkdownContent}
                setDialogOpen={setDialogOpen}
                lastItem={i !== fullArray.length - 1}
              />
            </MenuItem>
          ))}
      </Menu>
      <JobInvitationDialog
        open={dialogOpen}
        handleClose={() => {
          setDialogOpen(false);
          setMarkdownContent('');
        }}
        markdownContent={markdownContent}
      />
    </>
  );
}

interface JobInvitationItemProps {
  jobInvitation: z.infer<typeof NotificationSchema>;
  setMarkdownContent: (content: string) => void;
  setDialogOpen: (content: boolean) => void;
  lastItem: boolean;
}

export function JobInvitationItem({
  jobInvitation,
  setMarkdownContent,
  setDialogOpen,
  lastItem,
}: JobInvitationItemProps) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [hideMenuAnchorEl, setHideMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const hideOpen = Boolean(hideMenuAnchorEl);
  const readNotification = useReadNotification(jobInvitation.notification_id);

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <IconButton
          onClick={(event) => {
            setHideMenuAnchorEl(event.currentTarget);
          }}
          sx={{ color: '#202124', position: 'absolute', top: -20, right: 0 }}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={hideMenuAnchorEl}
          open={hideOpen}
          onClose={() => setHideMenuAnchorEl(null)}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          sx={{
            '& .MuiPaper-root': {
              boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <MenuItem
            onClick={() => {
              readNotification.mutate(undefined, {
                onError: () => {
                  enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
                },
                onSuccess: () => {
                  enqueueSnackbar('', {
                    variant: 'default',
                    content: () => (
                      <SnackbarContent>
                        <Card
                          sx={{
                            bgcolor: '#38853C',
                            width: '100%',
                            padding: '16px 22px',
                            color: 'white',
                            fontWeight: 600,
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '0.875rem',
                            border: 'solid 1px #cdcdcd',
                          }}
                        >
                          <CheckCircleIcon
                            fontSize="small"
                            style={{ marginRight: 6, position: 'relative', left: -1 }}
                          />
                          <div>
                            {intl.formatMessage(
                              { id: 'ratings.notificationHidden' },
                              {
                                link: (
                                  <Typography
                                    component="span"
                                    sx={{
                                      pb: 0.5,
                                      '&:active': { color: grey[100] },
                                      fontSize: '0.875rem',
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                    }}
                                    onClick={() => history.push(`${urlPrefix}/hidden-items`)}
                                  >
                                    {intl.formatMessage({ id: 'ratings.dislikedItem.here' })}
                                  </Typography>
                                ),
                              }
                            )}
                          </div>
                        </Card>
                      </SnackbarContent>
                    ),
                  });
                },
              });
              setHideMenuAnchorEl(null);
            }}
            id="hide-recommendation-training-link"
          >
            {intl.formatMessage({ id: 'notifications.hide' })}
          </MenuItem>
        </Menu>
      </Box>
      <Box
        sx={{
          px: 3,
        }}
      >
        <Typography sx={{ maxWidth: '100%', position: 'relative', fontWeight: 700, mb: 0.5 }}>
          {jobInvitation.data.job_name}
        </Typography>
        <Typography sx={{ maxWidth: '100%', mb: 1 }}>{jobInvitation.data.employer_name}</Typography>
        <Typography sx={{ fontWeight: 300, color: grey['600'], fontSize: 15 }}>{`${intl.formatMessage({
          id: 'jobInvitations.invitedOn',
        })} ${format(new Date(jobInvitation.data.contacted_at), 'MM/dd/yyyy')}`}</Typography>
        <Box sx={{ mt: 2, mb: lastItem ? 1 : 0 }}>
          <Button
            variant="outlined"
            sx={{ fontSize: 14, lineHeight: 1.4, px: 2, mr: 1 }}
            onClick={() => {
              setMarkdownContent(jobInvitation.data.markdown_html);
              setDialogOpen(true);
            }}
          >
            {intl.formatMessage({ id: 'jobInvitations.view' })}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

interface JobInvitationRatingsProps {
  jobInvitation: z.infer<typeof NotificationSchema>;
}

export function JobInvitationRatings({ jobInvitation }: JobInvitationRatingsProps) {
  const readNotification = useReadNotification(jobInvitation.notification_id, false);

  const intl = useIntl();

  return (
    <Grid xs={12} md={4}>
      <Card
        sx={{
          height: '100%',
          boxShadow: 'none',
          border: 'solid 1px rgba(0, 0, 0, 0.12)',
        }}
      >
        <CardHeader
          action={
            <Button
              startIcon={<ReplyOutlinedIcon sx={{ color: 'primary.dark' }} />}
              onClick={() => readNotification.mutate()}
              sx={{ color: 'primary.dark', px: 2 }}
              id="save-jobs-button"
            >
              {intl.formatMessage({ id: 'ratings.notifications.unhide' })}
            </Button>
          }
          sx={{ px: 2.5 }}
        />
        <CardContent
          sx={{
            px: 3,
            pt: 0.5,
            pb: 3,
          }}
        >
          <Typography sx={{ maxWidth: '100%', position: 'relative', fontWeight: 700, mb: 0.5 }}>
            {jobInvitation.data.job_name}
          </Typography>
          <Typography sx={{ maxWidth: '100%', mb: 1 }}>{jobInvitation.data.employer_name}</Typography>
          <Typography sx={{ fontWeight: 300, color: grey['600'], fontSize: 15 }}>{`${intl.formatMessage({
            id: 'jobInvitations.invitedOn',
          })} ${format(new Date(jobInvitation.data.contacted_at), 'MM/dd/yyyy')}`}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

import { useMemo, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import PDFViewer from './PDFViewer';
import PDFDocumentTemplate from './PDFDocumentTemplate';
import ResumeEditor from './ResumeEditor';
import Loading from 'components/Loading';
import useResumeDataQuery, { UseResumeDataQuery } from 'lib/hooks/useResumeDataQuery';

function ResumeBuilder() {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const resumeDataQuery = useResumeDataQuery();

  const [formValues, setFormValues] = useState<UseResumeDataQuery['data'] | null>(resumeDataQuery.data ?? null);

  const memoizedValue = useMemo(() => <PDFDocumentTemplate resumeData={formValues} />, [formValues]);

  function updateFormValues(newValues: UseResumeDataQuery['data']) {
    setFormValues(newValues);
  }

  function handleDownload() {
    if (pdfUrl) {
      const currentDate = new Date().toLocaleDateString('en-US').replace(/\//g, '_');
      // Output: "resume_{first_name}_{last_name}_{date}.pdf"
      const fileName = `resume_${formValues?.header.name.replace(/\s+/g, '_')}_${currentDate}.pdf`;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = fileName;
      link.click();
    }
  }

  function handleChangePdfUrl(value: string) {
    setPdfUrl(value);
  }

  if (resumeDataQuery.isLoading) return <Loading />;

  return (
    <Container>
      <Box mb={5} />
      <Box sx={{ mb: 5, pt: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ResumeEditor
              resumeInitialValues={resumeDataQuery.data}
              handleDownload={handleDownload}
              updateFormValues={updateFormValues}
              formValues={formValues as UseResumeDataQuery['data']}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ position: 'sticky', top: 24 }}>
              {formValues && !resumeDataQuery.isLoading && (
                <PDFViewer handleChangePdfUrl={handleChangePdfUrl} value={memoizedValue} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default ResumeBuilder;

import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Alert, AlertTitle } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { bytesToSize } from 'lib/utils';
import { connect } from 'formik';
import { Box } from '@mui/material';

/* Styles */
import styles from 'styles/browse-documents.module.scss';
import applyJobStyles from 'styles/apply-job-form.module.scss';
import cssVars from 'styles/vars.module.scss';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import Button from '@mui/material/Button';

const useStyles = makeStyles(() => ({
  dragActive: {
    border: `dashed 1px ${cssVars.actionBlue} !important`,
    opacity: 0.5,
  },
}));

function BrowseDocuments({
  className,
  onDrop,
  disabled,
  label = 'jobSearchActivity.form.uploadPlaceholder',
  buttonId = 'browse-documents-work-search-button',
  ...props
}) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const intl = useIntl();
  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (disabled) {
        return;
      }
      // Read file contents and store base64 encoded data to
      // File object in 'contents' attribute
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          file.contents = reader.result;
          const tokens = file.name.split('.');
          let ext = '';
          if (tokens.length > 1) {
            file.ext = '.' + tokens.pop();
            ext = file.ext;
          }
          if (onDrop) {
            onDrop(reader.result, ext);
          }
        };
        reader.readAsDataURL(file);
      });
      setFiles(acceptedFiles);

      const {
        field: { name },
        form: { setFieldValue },
      } = props;

      setFieldValue(name, acceptedFiles);
    },
    [props, onDrop]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections, maxSize } = useDropzone({
    onDrop: handleDrop,
    accept:
      'text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf, image/jpeg, image/png, image/gif',
    minSize: 0,
    maxSize: 10000000,
    maxFiles: 1,
    multiple: false,
    disabled: disabled,
  });

  const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].size > maxSize;

  const fileRejectionItems = fileRejections.map(({ file }) => (
    <Alert severity="error" key={file.path}>
      <AlertTitle>
        {file.path} - {file.size} bytes
      </AlertTitle>
      <Typography variant="body1" gutterBottom={0}>
        <FormattedMessage id="forms.enroll.fileFormats" defaultMessage="Must be a (.doc, .docx, .pdf, .txt) format" />
      </Typography>
    </Alert>
  ));

  return (
    <div className={cn(classes.root, className)} {...props}>
      {files.length === 0 ? (
        <div
          className={cn({
            [styles.dropZone]: true,
            [styles.dropZoneDisabled]: disabled,
            [classes.dragActive]: isDragActive,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} title={intl.formatMessage({ id: label })} />
          <Box
            sx={{
              display: 'flex',
              minHeight: 82,
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ color: '#424242', paddingTop: { xs: 0, md: 0.5 }, display: 'inline', fontSize: 13 }}>
              <FormattedMessage id={label} />
            </Box>
            <Box align="left" sx={{ pb: { xs: 0.9, md: 0.7 }, pt: { xs: 0.4, md: 0.3 } }}>
              <Button color="primary" size="large" variant="contained" disabled={disabled} type="button" id={buttonId}>
                {intl.formatMessage({ id: 'experience.form.browse' })}
              </Button>
            </Box>
            <span className={applyJobStyles.fieldDescription}>
              <FormattedMessage id="jobSearchActivity.form.weCanRead" />
            </span>
          </Box>
        </div>
      ) : null}
      {files.length > 0 && (
        <>
          <Typography variant="body2" style={{ fontSize: 13, marginTop: 2 }}>
            <FormattedMessage id={label} /> <FormattedMessage id="jobSearchActivity.form.weCanRead" />
          </Typography>
          <Box>
            {files.map((file, i) => (
              <Box
                key={i}
                style={{
                  borderRadius: 4,
                  backgroundColor: '#e9ecef',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 12,
                }}
              >
                <Box display="flex">
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <FileCopyIcon style={{ color: cssVars.secondaryColorMain, minWidth: 56, fontSize: 24 }} />
                  </Box>
                  <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="caption">{file.name}</Typography>
                    <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                      {bytesToSize(file.size)}
                    </Typography>
                  </Box>
                </Box>
                <Box pr={2}>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      const {
                        field: { name },
                        form: { setFieldValue },
                      } = props;
                      const newFilesArray = files.slice(0, i).concat(files.slice(i + 1));
                      setFiles(newFilesArray);
                      setFieldValue(name, newFilesArray);
                    }}
                    size="large"
                  >
                    <CancelOutlinedIcon sx={{ color: (theme) => theme.palette.error.main }} />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      {isFileTooLarge && (
        <Alert severity="error">
          <FormattedMessage id="forms.enroll.uploadErrorTooLarge" defaultMessage="File is too large" />
        </Alert>
      )}
      {fileRejections && <div>{fileRejectionItems}</div>}
    </div>
  );
}

BrowseDocuments.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  buttonId: PropTypes.string,
  onDrop: PropTypes.func,
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string,
  }),
  form: PropTypes.shape({
    setValue: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
};

export default connect(BrowseDocuments);

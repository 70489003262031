import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { Field, FieldProps } from 'formik';
import NumericFormat from 'react-number-format';

interface PhoneInputFieldProps {
  name: string;
  label: string;
  helperText?: string;
  disabled?: boolean;
  error?: boolean;
}

export default function PhoneInputField({ name, label, helperText, disabled, error }: PhoneInputFieldProps) {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl disabled={disabled} error={error}>
          <FormLabel htmlFor={field.name}>{label}</FormLabel>

          <NumericFormat
            id={field.name}
            aria-labelledby={field.name}
            name={field.name}
            value={field.value}
            onValueChange={({ value }) => {
              form.getFieldHelpers(field.name).setValue(value);
            }}
            customInput={OutlinedInput}
            displayType={'input'}
            type={'tel'}
            format="(###) ###-####"
            placeholder="(###) ###-####"
            mask="_"
          />

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
}

import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexRowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  bullet: {
    height: '100%',
  },
});

export const spacing = {
  0: '0pt',
  0.5: '3pt',
  1: '6pt',
  1.5: '9pt',
  2: '12pt',
  2.5: '15pt',
  3: '18pt',
  3.5: '21pt',
  4: '24pt',
  4.5: '27pt',
  5: '30pt',
  6: '36pt',
  7: '42pt',
  8: '48pt',
  9: '54pt',
  10: '60pt',
  11: '66pt',
  12: '72pt',
};

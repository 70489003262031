import { apiRequest } from 'shared/API';
import { z } from 'zod';

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const EmptyRequestSchema = z.void();
export const EmptyObjectSchema = z.object({});

interface ApiOptions {
  customPath?: string;
}

export function api<Request, Response>({
  method,
  path,
  requestSchema,
  responseSchema,
}: {
  method: HTTPMethod;
  path: string;
  requestSchema: z.ZodType<Request, z.ZodTypeDef, unknown>;
  responseSchema: z.ZodType<Response, z.ZodTypeDef, unknown>;
}) {
  return function (requestData: Request, options?: ApiOptions, signal?: AbortSignal) {
    requestSchema.parse(requestData);

    async function apiCall() {
      const response = await apiRequest(method, options?.customPath ?? path, {
        [method === HTTPMethod.GET ? 'params' : 'data']: requestData,
        ...(signal ? { signal } : {}),
      });
      return responseSchema.parse(response);
    }

    return apiCall();
  };
}

import { Text, View, Document, Page, Font, Link } from '@react-pdf/renderer';
import { spacing, styles } from './styles';
import { format } from 'date-fns';
import arialRegular from '../../assets/fonts/ARIAL.TTF';
import arialBold from '../../assets/fonts/ARIALBD.TTF';
import arialItalic from '../../assets/fonts/ARIALI.TTF';
import { UseResumeDataQuery } from 'lib/hooks/useResumeDataQuery';
import { formatPhoneNumber } from 'lib/utils';
import { Fragment } from 'react';

Font.register({
  family: 'Arial',
  fonts: [{ src: arialRegular }, { src: arialBold, fontWeight: 600 }, { src: arialItalic, fontStyle: 'italic' }],
});

function PDFDocumentTemplate({ resumeData }: { resumeData: UseResumeDataQuery['data'] | null }) {
  if (!resumeData) return null;
  return (
    <Document>
      <Page size="A4" style={{ ...styles.flexCol, fontSize: '12pt', fontFamily: 'Arial' }}>
        <View
          style={{
            ...styles.flexCol,
            padding: `${spacing[4]} ${spacing[6]}`,
          }}
        >
          {/* HEADER SECTION */}
          <View style={{ ...styles.flexCol, marginBottom: spacing[2] }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: '20pt',
                textTransform: 'uppercase',
                marginBottom: spacing[0.5],
              }}
            >
              {resumeData.header.name}
            </Text>
            <Text style={{ textAlign: 'center' }}>
              {[
                resumeData.header.location,
                resumeData.header.phone_number &&
                  resumeData.header.phone_number.length === 10 &&
                  `P:${formatPhoneNumber(resumeData.header.phone_number)}`,
                resumeData.header.email && (
                  <Link style={{ color: 'black', textDecoration: 'none' }} src={`mailto:${resumeData.header.email}`}>
                    {resumeData.header.email}
                  </Link>
                ),
                resumeData.header.url && (
                  <Link style={{ color: 'black', textDecoration: 'none' }} src={`https://${resumeData.header.url}`}>
                    {resumeData.header.url}
                  </Link>
                ),
              ]
                .filter(Boolean) // Filter out falsy values
                .map((item, index) => (
                  <Fragment key={index}>
                    {index > 0 && ' | '}
                    {item}
                  </Fragment>
                ))}
            </Text>
          </View>

          {resumeData.sections.map((section) => {
            switch (section.type) {
              case 'summary':
                if (section.items.length <= 0 || !section.items[0]) return null;
                return section.items.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      ...styles.flexCol,
                      marginBottom: spacing[2],
                    }}
                  >
                    <Text
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        marginBottom: spacing[1],
                      }}
                    >
                      Summary
                    </Text>

                    <Text>{item}</Text>
                  </View>
                ));
              case 'skills':
                if (section.items.length <= 0) return null;
                return (
                  <View
                    key={section.type}
                    style={{
                      ...styles.flexCol,
                      marginBottom: spacing[2],
                    }}
                  >
                    <Text
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        marginBottom: spacing[1],
                      }}
                    >
                      Top Skills
                    </Text>

                    <Text>{section.items.map((skill) => skill.name).join(' * ')}</Text>
                  </View>
                );
              case 'work_experience':
                if (section.items.length <= 0) return null;
                return (
                  <View
                    key={section.type}
                    style={{
                      ...styles.flexCol,
                      marginBottom: spacing[2],
                    }}
                  >
                    <Text
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        marginBottom: spacing[1],
                      }}
                    >
                      Work Experience
                    </Text>

                    {section.items.map((work, index) => (
                      <View key={index} style={{ marginBottom: spacing[1] }}>
                        <View style={styles.flexRowBetween}>
                          <Text style={{ fontWeight: 'bold' }}>{work.employer_provider}</Text>
                          <Text>
                            {format(new Date(work.start_date), 'MMM yyyy')} –{' '}
                            {work.end_date ? format(new Date(work.end_date), 'MMM yyyy') : 'Present'}
                          </Text>
                        </View>

                        <View style={styles.flexRowBetween}>
                          <Text>{work.description}</Text>
                        </View>

                        <Text>Skills: {work.skills.map((skill) => skill.name).join(' * ')}</Text>
                      </View>
                    ))}
                  </View>
                );
              case 'others':
                if (section.items.length <= 0) return null;
                return (
                  <View
                    key={section.type}
                    style={{
                      ...styles.flexCol,
                      marginBottom: spacing[2],
                    }}
                  >
                    <Text
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        marginBottom: spacing[1],
                      }}
                    >
                      Education and Certifications
                    </Text>

                    {section.items.map((education, index) => (
                      <View key={index} style={{ marginBottom: spacing[1] }}>
                        <View style={styles.flexRowBetween}>
                          <Text style={{ fontWeight: 'bold' }}>{education.employer_provider}</Text>
                          <Text>
                            {format(new Date(education.start_date), 'MMM yyyy')} –{' '}
                            {education.end_date ? format(new Date(education.end_date), 'MMM yyyy') : 'Present'}
                          </Text>
                        </View>

                        <View style={styles.flexCol}>
                          <Text>{education.description}</Text>
                          {education.skills.length > 0 ? (
                            <Text>Skills: {education.skills.map((skill) => skill.name).join(' * ')}</Text>
                          ) : null}
                        </View>
                      </View>
                    ))}
                  </View>
                );
              default:
                return null;
            }
          })}
        </View>
      </Page>
    </Document>
  );
}

export default PDFDocumentTemplate;

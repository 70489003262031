import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';

/* UI Elements */
import {
  Box,
  Typography,
  Button,
  Select,
  FormControl,
  MenuItem,
  TextField,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { FormControlLabel, Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExperienceWalletSkillsAutocomplete from './ExperienceWalletSkillsAutocomplete';
import ExperienceWalletFieldsAutocomplete from './ExperienceWalletFieldsAutocomplete';
import { pathOr } from 'ramda';

/* Redux */
import { useSelector } from 'react-redux';

/* i18n */
import { useIntl } from 'react-intl';
import { typeDict } from './ExperienceWalletDrawer';

import { apiRequest } from 'lib/API';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import { useQueryClient } from '@tanstack/react-query';

export function TypeSelect({
  onChange = () => {},
  onChangeCapture = () => {},
  value = '',
  disabled = false,
  name = 'type',
  onboarding = false,
  inputId = 'type-select-input',
}) {
  const intl = useIntl();

  return (
    <Select
      onChangeCapture={onChangeCapture}
      displayEmpty
      disabled={disabled}
      name={name}
      onChange={onChange}
      value={value}
      renderValue={(selected) => {
        if (!selected.length) {
          return intl.formatMessage({ id: 'experience.form.experiencePlaceholder' });
        }
        return intl.formatMessage({ id: typeDict[selected] });
      }}
      MenuProps={{
        sx: {
          maxHeight: 440,
          '& .MuiPaper-root': {
            borderRight: '1px solid #ccc', // Set the border style and color
            borderBottom: '1px solid #ccc', // Set the border style and color
            borderLeft: '1px solid #ccc', // Set the border style and color
          },
        },
      }}
      sx={{
        '.MuiFilledInput-input': {
          borderRadius: '4px 4px 0 0 !important',
          maxHeight: 43,
          boxSizing: 'border-box',
          padding: '15px 11px 13px!important',
        },
        '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
      }}
      inputProps={{
        title: intl.formatMessage({ id: 'experience.form.experienceTypeLabel' }),
        id: inputId,
      }}
      variant="filled"
      id="goals-add-experience-dropdown"
    >
      <MenuItem value="apprenticeship" id={`apprenticeship-${onboarding ? 'onboard-' : ''}dropdown`}>
        {intl.formatMessage({ id: 'experience.form.apprenticeshipType' })}
      </MenuItem>
      <MenuItem value="assessment" id={`assessment-${onboarding ? 'onboard-' : ''}dropdown`}>
        {intl.formatMessage({ id: 'experience.form.assessmentType' })}
      </MenuItem>
      <MenuItem value="badge" id={`badge-${onboarding ? 'onboard-' : ''}dropdown`}>
        {intl.formatMessage({ id: 'experience.form.badgeType' })}
      </MenuItem>
      <MenuItem value="course" id={`course-${onboarding ? 'onboard-' : ''}dropdown`}>
        {intl.formatMessage({ id: 'experience.form.courseType' })}
      </MenuItem>
      <MenuItem value="certification" id={`certification-${onboarding ? 'onboard-' : ''}dropdown`}>
        {intl.formatMessage({ id: 'experience.form.certificateType' })}
      </MenuItem>
      <MenuItem value="degree" id={`education-${onboarding ? 'onboard-' : ''}dropdown`}>
        {intl.formatMessage({ id: 'experience.form.educationDegreeType' })}
      </MenuItem>
      <MenuItem value="employment" id={`employment-${onboarding ? 'onboard-' : ''}dropdown`}>
        {intl.formatMessage({ id: 'experience.form.employmentType' })}
      </MenuItem>
      <MenuItem value="internship" id={`internship-${onboarding ? 'onboard-' : ''}dropdown`}>
        {intl.formatMessage({ id: 'experience.form.internshipType' })}
      </MenuItem>
      <MenuItem value="license" id={`license-${onboarding ? 'onboard-' : ''}dropdown`}>
        {intl.formatMessage({ id: 'experience.form.licenseType' })}
      </MenuItem>
    </Select>
  );
}

TypeSelect.propTypes = {
  onChange: PropTypes.func,
  onChangeCapture: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  inputId: PropTypes.string,
  disabled: PropTypes.bool,
  onboarding: PropTypes.bool,
};

export default function ExperienceWalletForm({
  experience: { type, field, description, skills, start_date, end_date, employer_provider, id, ...rest },
  experiences,
  setCurrentContent,
  currentIndex,
  replaceItem,
  appendItem,
  onSubmit,
}) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { results: careers } = useSelector((state) => state.careers);
  const queryClient = useQueryClient();

  const [skillsOpen, setSkillsOpen] = useState(false);
  const [fieldOpen, setFieldOpen] = useState(false);
  const [isCurrentJob, setIsCurrentJob] = useState(false);
  const [isExperienceCurrent, setIsExperienceCurrent] = useState(rest.is_current ?? false);
  const [fieldSuggestion, setFieldSuggestion] = useState('');
  const [gettingSkills, setGettingSkills] = useState(false);
  const initialValues = {
    type,
    field,
    description,
    skills,
    start_date,
    end_date,
    employer_provider,
  };

  const placeholderDict = {
    apprenticeship: intl.formatMessage({ id: 'experience.form.apprenticeshipDescriptionPlaceholder' }),
    assessment: intl.formatMessage({ id: 'experience.form.assessmentDescriptionPlaceholder' }),
    badge: intl.formatMessage({ id: 'experience.form.badgeDescriptionPlaceholder' }),
    course: intl.formatMessage({ id: 'experience.form.courseDescriptionPlaceholder' }),
    degree: intl.formatMessage({ id: 'experience.form.degreeDescriptionPlaceholder' }),
    license: intl.formatMessage({ id: 'experience.form.licenseDescriptionPlaceholder' }),
    certification: intl.formatMessage({ id: 'experience.form.certificationDescriptionPlaceholder' }),
    internship: intl.formatMessage({ id: 'experience.form.internshipDescriptionPlaceholder' }),
    employment: intl.formatMessage({ id: 'experience.form.jobDescriptionPlaceholder' }),
  };

  useEffect(() => {
    if (replaceItem && !end_date) {
      setIsCurrentJob(true);
    }
  }, [end_date, replaceItem]);

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};

        const lettersNumbers = /^[^<>]+$/; // eslint-disable-line no-useless-escape
        if (!values.type) {
          errors.type = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
        }
        if (!values.field || !values.field?.soc) {
          errors.field = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
        }
        if (!values.description) {
          errors.description = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
        }
        if (!values.skills?.length) {
          errors.skills = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
        }
        if (!values.start_date) {
          errors.start_date = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
        }
        if (!isCurrentJob && values.end_date && values.end_date < values.start_date) {
          errors.end_date = intl.formatMessage({ id: 'experience.form.endDatePrior' });
        }
        if (!isCurrentJob && !isExperienceCurrent && !values.end_date) {
          errors.end_date = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
        }
        if (!values.employer_provider) {
          errors.employer_provider = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
        }
        if (values.employer_provider && !lettersNumbers.test(values.employer_provider)) {
          errors.employer_provider = intl.formatMessage({ id: 'profile.validation.basicPunctiation' });
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (onSubmit) {
          onSubmit(values);
          return;
        }
        if (appendItem || replaceItem) {
          let newExperiences = experiences.map((exp) => {
            const { field, fields, ...rest } = exp; // eslint-disable-line
            return { fields: [field], ...rest };
          });
          const itemToAddOrUpdate = {
            description: values.description,
            employer_provider: values.employer_provider,
            skills: values.skills,
            start_date:
              typeof values.start_date === 'string' ? values.start_date : values.start_date.toISOString().slice(0, 10),
            ...((values.type === 'employment' && !isCurrentJob) || values.type !== 'employment'
              ? {
                  end_date:
                    typeof values.end_date === 'string'
                      ? values.end_date
                      : values.end_date
                      ? values.end_date.toISOString().slice(0, 10)
                      : null,
                }
              : {}),
            ...(values.type !== 'employment' ? { is_current: isExperienceCurrent } : {}),
            type: values.type,
            fields: [{ soc: values?.field?.soc, title: values?.field?.title }],
          };
          if (appendItem) {
            newExperiences = [...newExperiences, itemToAddOrUpdate];
          } else if (replaceItem) {
            newExperiences[currentIndex] = itemToAddOrUpdate;
          }
          setSubmitting(true);
          trackEvent('EXPERIENCE_SAVE');
          apiRequest('PUT', '/users/me/experience/', {
            data: {
              job_experience: newExperiences.map((exp) => {
                const { id, ...rest } = exp; // eslint-disable-line
                return rest;
              }),
            },
          })
            .then(() => {
              queryClient.invalidateQueries({ queryKey: ['/users/me/experience/'] });
              const { soc, title } = values.field;
              const newField = { soc, title };
              if (appendItem) {
                appendItem({
                  ...itemToAddOrUpdate,
                  field: newField,
                });
                trackEvent('EXPERIENCE_MANUAL_ADD');
              }
              if (replaceItem) {
                if (!itemToAddOrUpdate.wallet) {
                  trackEvent('EXPERIENCE_MANUAL_EDIT', itemToAddOrUpdate);
                }
                replaceItem(currentIndex, {
                  id,
                  ...itemToAddOrUpdate,
                  field: newField,
                });
              }
              if (values.type) {
                trackEvent('EXPERIENCE_TYPE', values.type);
              }
              setSubmitting(false);
              setCurrentContent('display');
              return;
            })
            .catch((error) => {
              setSubmitting(false);
              if (error.toJSON()?.message === 'Request failed with status code 400') {
                setFieldSuggestion(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }));
              }
            });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              py: 2,
              mb: 2,
              mt: 1.5,
            }}
          >
            {/*JSON.stringify(values)*/}
            <Typography sx={{ fontWeight: 700, pr: 1 }}>
              {intl.formatMessage({
                id: replaceItem ? 'experience.form.editExperience' : 'experience.form.addExperience',
              })}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 700 }}>
              {intl.formatMessage({ id: 'experience.form.experienceLabel' })}
              <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                *
              </Typography>
            </Typography>
            <FormControl variant="filled" error={touched.type && Boolean(errors.type)}>
              <TypeSelect
                onChange={(e) => {
                  setIsCurrentJob(false);
                  handleChange(e);
                }}
                value={values.type}
                disabled={!appendItem && rest.wallet}
              />
              {touched.type && errors.type ? <FormHelperText>{errors.type}</FormHelperText> : null}
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 700 }}>
              {intl.formatMessage({ id: 'experience.form.description' })}
              <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                *
              </Typography>
            </Typography>
            <Box>
              <TextField
                sx={{
                  '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                  '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                }}
                error={touched.description && Boolean(errors.description)}
                fullWidth
                helperText={touched.description && errors.description}
                margin="normal"
                name="description"
                disabled={!appendItem && rest.wallet}
                onBlur={handleBlur}
                onChange={handleChange}
                onBlurCapture={(e) => {
                  if (e.target.value && values.type === 'employment') {
                    setGettingSkills(true);
                    setSkillsOpen(false);
                    setFieldOpen(false);
                    apiRequest('GET', '/users/me/skills/socs', {
                      params: { description: e.target.value },
                    })
                      .then((r) => {
                        setFieldValue('skills', r?.skills.slice(0, 5));
                        setFieldSuggestion('');
                        if (pathOr(false, ['soc', 0], r)) {
                          const suggestedSoc = r?.soc[0];
                          const newField = careers.reduce((prev, curr) => {
                            if (curr.soc === suggestedSoc) {
                              return curr;
                            }
                            return prev;
                          }, {});
                          if (newField.soc) {
                            setFieldValue('field', newField);
                          }
                        }
                        setGettingSkills(false);
                      })
                      .catch((error) => {
                        setGettingSkills(false);
                        setFieldValue('field', null);
                        if (error.toJSON()?.message === 'Request failed with status code 400') {
                          setFieldSuggestion(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }));
                        }
                      });
                  }
                }}
                type="text"
                placeholder={values.type ? placeholderDict[values.type] : ''}
                variant="filled"
                value={values.description}
                inputProps={{
                  sx: {
                    '&::placeholder': {
                      opacity: 1,
                    },
                  },
                  title: intl.formatMessage({ id: 'experience.form.description' }),
                }}
                id="experience-add-experience-dropdown"
              />
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 700 }}>
              {intl.formatMessage({ id: 'experience.form.fieldsLabel' })}
              <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                *
              </Typography>
            </Typography>
            <ExperienceWalletFieldsAutocomplete
              id="experience-select-field-dropdown"
              isDisabled={(!appendItem && rest.wallet) || gettingSkills}
              value={values.field}
              onChange={(_, newValue) => {
                setFieldSuggestion('');
                setFieldValue('field', newValue);
                setGettingSkills(true);
                setSkillsOpen(false);
                apiRequest('GET', '/users/me/skills/socs', {
                  params: { socs: newValue.soc, upvote_skills: 5 },
                }).then((r) => {
                  setFieldValue('skills', r?.skills.slice(0, 5));
                  setGettingSkills(false);
                });
              }}
              helperText={fieldSuggestion ?? (touched.field && errors.field ? errors.field : '')}
              error={(touched.field && Boolean(errors.field)) || Boolean(fieldSuggestion)}
              open={fieldOpen}
              setOpen={setFieldOpen}
            />
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 700 }}>
              {intl.formatMessage({ id: 'experience.form.selectASkill' })}
              <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                *
              </Typography>
            </Typography>
            {gettingSkills ? (
              <Box mt={2} mx={2} mb={3} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <ExperienceWalletSkillsAutocomplete
                value={values.skills ?? []}
                onChange={(_, newValue) => {
                  const maxSkillsAllowed = 100;
                  // If removing instead of adding
                  if (newValue.length < values.skills?.length) {
                    const newIds = newValue.map((sk) => sk.id);
                    // We find the deleted item
                    const deletedItem = values.skills?.reduce((acc, curr) => {
                      if (!newIds.includes(curr.id)) {
                        return curr;
                      }
                      return acc;
                    }, {});
                    // Preventing removing wallet items
                    if (deletedItem.wallet) {
                      return;
                    }
                  }
                  setFieldValue('skills', newValue.slice(0, maxSkillsAllowed));
                  if (newValue.length > maxSkillsAllowed) {
                    enqueueSnackbar(intl.formatMessage({ id: 'experience.form.maxSkills' }, { n: maxSkillsAllowed }), {
                      variant: 'error',
                    });
                  }
                  if (newValue.length > values.skills.length) {
                    trackEvent('EXPERIENCE_MANUAL_SKILLSADD');
                  } else {
                    trackEvent('EXPERIENCE_MANUAL_SKILLSREMOVE');
                  }
                }}
                helperText={touched.skills && errors.skills}
                error={touched.skills && Boolean(errors.skills)}
                isDisabled={gettingSkills}
                open={skillsOpen}
                setOpen={setSkillsOpen}
                id="skills-add-experience-dropdown"
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 700 }}>
              {intl.formatMessage({ id: 'experience.form.duration' })}
              <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                *
              </Typography>
            </Typography>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
              <DatePicker
                label={intl.formatMessage({ id: 'experience.form.startDate' })}
                sx={{ '.MuiInputBase-root': { backgroundColor: 'white' } }}
                disabled={!appendItem && rest.wallet}
                slotProps={{
                  textField: {
                    variant: 'filled',
                    helperText: touched.start_date && errors.start_date,
                    error: touched.start_date && Boolean(errors.start_date),
                    id: 'start-date-experience-dropdown',
                  },
                }}
                name="start_date"
                onChange={(newValue) => {
                  setFieldValue('start_date', newValue);
                }}
                value={
                  typeof values.start_date === 'string'
                    ? new Date(`${values.start_date}T00:00:00`)
                    : typeof values.start_date === 'object'
                    ? values.start_date
                    : null
                }
              />
              {!isCurrentJob ? (
                <DatePicker
                  label={intl.formatMessage({ id: 'experience.form.endDate' })}
                  sx={{ '.MuiInputBase-root': { backgroundColor: 'white' } }}
                  slotProps={{
                    textField: {
                      variant: 'filled',
                      helperText: touched.end_date && errors.end_date,
                      error: touched.end_date && Boolean(errors.end_date),
                      id: 'end-date-experience-dropdown',
                    },
                  }}
                  disabled={!appendItem && rest.wallet}
                  name="end_date"
                  onChange={(newValue) => {
                    setFieldValue('end_date', newValue);
                  }}
                  value={
                    typeof values.end_date === 'string'
                      ? new Date(`${values.end_date}T00:00:00`)
                      : typeof values.end_date === 'object'
                      ? values.end_date
                      : null
                  }
                />
              ) : null}
            </Box>
            {values.type === 'employment' ? (
              <FormControl sx={{ mb: 1, position: 'relative', top: -2, left: 2 }}>
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: 12,
                    },
                  }}
                  label={intl.formatMessage({ id: 'onboarding.experience.currentJob' })}
                  control={
                    <Checkbox
                      sx={{ padding: '0 8px 0 8px' }}
                      color="primary"
                      checked={isCurrentJob}
                      onChange={(e) => {
                        setIsCurrentJob(e.currentTarget.checked);
                      }}
                    />
                  }
                  labelPlacement="end"
                />
              </FormControl>
            ) : (
              values.type !== '' && (
                <FormControl sx={{ mb: 1, position: 'relative', top: -2, left: 2 }}>
                  <FormControlLabel
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: 12,
                      },
                    }}
                    label={intl.formatMessage({ id: 'onboarding.experience.experienceCurrent' })}
                    control={
                      <Checkbox
                        sx={{ padding: '0 8px 0 8px' }}
                        color="primary"
                        checked={isExperienceCurrent}
                        onChange={(e) => {
                          setIsExperienceCurrent(e.currentTarget.checked);
                        }}
                      />
                    }
                    labelPlacement="end"
                  />
                </FormControl>
              )
            )}
          </Box>
          <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 700 }}>
              {intl.formatMessage({ id: 'experience.form.provider' })}
              <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                *
              </Typography>
            </Typography>
            <Box>
              <TextField
                sx={{
                  '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                  '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                }}
                error={touched.employer_provider && Boolean(errors.employer_provider)}
                fullWidth
                disabled={!appendItem && rest.wallet}
                helperText={touched.employer_provider && errors.employer_provider}
                placeholder={intl.formatMessage({ id: 'experience.form.provider' })}
                margin="normal"
                name="employer_provider"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="filled"
                inputProps={{
                  sx: {
                    '&::placeholder': {
                      opacity: 1,
                    },
                  },
                  title: intl.formatMessage({ id: 'experience.form.provider' }),
                }}
                value={values.employer_provider ?? ''}
                id="employer-provider-add-experience-dropdown"
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
            <Button
              aria-label="cancel"
              variant="outlined"
              color="inherit"
              sx={{ opacity: 0.6, mr: 2 }}
              onClick={() => setCurrentContent('display')}
              type="button"
              disabled={isSubmitting || gettingSkills}
              id="cancel-experience-dropdown"
            >
              {intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}
            </Button>
            {
              <Button
                aria-label="done"
                variant="outlined"
                color="secondary"
                type="submit"
                disabled={isSubmitting || gettingSkills}
                id={appendItem ? 'add-experience-dropdown' : 'done-experience-button'}
              >
                {intl.formatMessage({ id: appendItem ? 'experience.add' : 'experience.form.done' })}
              </Button>
            }
          </Box>
        </form>
      )}
    </Formik>
  );
}
ExperienceWalletForm.propTypes = {
  currentIndex: PropTypes.number,
  setCurrentContent: PropTypes.func,
  replaceItem: PropTypes.func,
  appendItem: PropTypes.func,
  experience: PropTypes.shape({
    id: PropTypes.string,
    field: PropTypes.object,
    description: PropTypes.string,
    type: PropTypes.string,
    start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    end_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    employer_provider: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slice: PropTypes.func,
      })
    ),
  }),
  experiences: PropTypes.array,
  onSubmit: PropTypes.func,
};

/* API */
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getNotifications } from 'lib/API/notifications';

const useJobInvitations = (enabled: boolean, read = false) => {
  const { isAuthenticated } = useAuth0();

  const idQuery = useQuery({
    queryKey: ['/users/me'],
    enabled: isAuthenticated,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  const additionalRequests = isAuthenticated && idQuery.isSuccess;

  return useQuery({
    queryKey: ['/users/me/notifications/', { read }],
    refetchOnMount: 'always',
    cacheTime: 0,
    enabled: enabled && additionalRequests,
    refetchOnWindowFocus: false,
    queryFn: () => getNotifications({ limit: 100, offset: 0, read }),
  });
};

export default useJobInvitations;

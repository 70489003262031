import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import SearchActivityCard from 'components/SearchActivityCard';
import { getWeekArr } from 'components/JobSearchActivity';
import { uuidv4 } from 'components/ExperienceWallet';

/* API */
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { apiRequest } from 'shared/API';

/* i18n */
import { useIntl } from 'react-intl';

import { useFormatDateRange } from 'shared/hooks';

/* Redux */
import { useSelector } from 'react-redux';

/* Material UI and other UI Dependencies */
import {
  Container,
  Box,
  Typography,
  useMediaQuery,
  Divider,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import { useTheme } from '@emotion/react';
import infoSvg from 'assets/images/Info_Cards.svg';

/* Forms */
import ApplyJobForm from 'components/forms/ApplyJobForm';
import AttendJobFair from 'components/forms/AttendJobFair';
import InterviewWithEmployer from 'components/forms/InterviewWithEmployer';
import Resea from 'components/forms/Resea';
import ConfirmationDialog from 'components/ConfirmationDialog';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: 'black',
  },
  tooltip: {
    backgroundColor: '#151515',
  },
}));

const claimDateToIso = (dateStr) => {
  const dateArr = dateStr.split('/');
  return `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`;
};

function formatDate(date) {
  const year = date.substr(2, 2);
  const month = date.substr(5, 2);
  const day = date.substr(8, 2);
  return `${parseInt(month, 10)}.${parseInt(day, 10)}.${year}`;
}

const ACTIVITIES_REQUIRED = 5;

const UIWorkSearchActivity = () => {
  const [activity, setActivity] = useState('');
  const [itemToEdit, setItemToEdit] = useState(null);
  const [showNewActivityBox, setShowActivityBox] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { palette } = useTheme();
  const tooltipClasses = useStylesBootstrap();
  const queryClient = useQueryClient();

  const isSmall = useMediaQuery('(max-width:767px)');
  const formatDateRange = useFormatDateRange();

  function activityString(from, to) {
    return `${intl.formatMessage({ id: 'jobSearchActivity.activityFor' })} ${formatDateRange(from, to)}`;
  }

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  let history = useHistory();

  const { selectedClaim } = useSelector((state) => state.ui);

  const { isFetching, data } = useQuery({
    queryKey: ['/users/me/worksearch/'],
    queryFn: async ({ queryKey }) => apiRequest('GET', queryKey[0]),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const weekArr = data ? getWeekArr(data?.status, data?.activities, true) : [];
  const claimWeek = weekArr.find((week) => week.to === claimDateToIso(selectedClaim.weekEnd));

  useEffect(() => {
    if (!selectedClaim.weekEnd || (data && !claimWeek)) {
      history.push(`${urlPrefix}/ui-dashboard`);
    }
  }, [selectedClaim, data, claimWeek]);

  // pushing user to /dashboard when no claim is selected on dashboard page
  if (!selectedClaim.weekEnd) {
    return null;
  }
  const addActivityMutation = useMutation({
    mutationFn: (values) => {
      return apiRequest(itemToEdit ? 'put' : 'post', '/users/me/worksearch/', { data: { activity: values } });
    },
    onMutate: (updatedData) => {
      const previousValue = queryClient.getQueryData(['/users/me/worksearch/']);

      queryClient.setQueryData(['/users/me/worksearch/'], (old) => {
        const clone = structuredClone(old) || {};

        if (itemToEdit) {
          return {
            ...clone,
            ...{
              activities: clone.activities.map((activity) => {
                if (activity.worksearch_id === updatedData.worksearch_id) {
                  return { ...activity, ...updatedData, saving: true };
                }
                return activity;
              }),
            },
          };
        }

        return {
          ...clone,
          ...{
            activities: [
              ...clone.activities,
              {
                ...updatedData,
                week_start_date: claimDateToIso(selectedClaim.weekStart),
                saving: true,
                worksearch_id: uuidv4(),
              },
            ],
          },
        };
      });

      return { previousValue };
    },
    onSuccess: (data) => {
      if (claimWeek?.items?.length >= 5) {
        history.push(`${urlPrefix}/file-a-claim`);
      } else if (itemToEdit) {
        queryClient.setQueryData(['/users/me/worksearch/'], (old) => {
          const clone = structuredClone(old) || {};
          setItemToEdit(null);
          return {
            ...clone,
            ...data,
            ...{
              //eslint-disable-next-line
              activities: clone.activities.map(({ saving, ...activity }) => {
                if (activity.worksearch_id === data?.activity?.worksearch_id) {
                  return { ...activity, ...data?.activity };
                }
                return activity;
              }),
            },
          };
        });
      } else {
        queryClient.setQueryData(['/users/me/worksearch/'], (old) => {
          const clone = structuredClone(old) || {};

          return {
            ...clone,
            ...data,
            ...{ activities: clone.activities.slice(0, clone.activities.length - 1).concat(data.activity) },
          }; //eslint-disable-line
        });
      }
    },
  });

  const completedCurrentWeek = data ? ((claimWeek?.items?.length ?? 0) / claimWeek?.required) * 100 : 0;

  const editActivityOptions = {
    applied_for_job: intl.formatMessage({ id: 'jobSearchActivity.applyForAJob' }),
    interview_with_employer: intl.formatMessage({ id: 'jobSearchActivity.interviewWithEmployer' }),
    attend_job_fair: intl.formatMessage({ id: 'jobSearchActivity.attendJobFair' }),
    participate_in_resea: intl.formatMessage({ id: 'jobSearchActivity.participateInWorkshop' }),
  };

  const handleContinue = () => {
    if (claimWeek?.items?.length >= 5) {
      history.push(`${urlPrefix}/file-a-claim`);
    } else {
      setConfirmationOpen(true);
    }
  };

  const formsDict = {
    applied_for_job: ApplyJobForm,
    interview_with_employer: InterviewWithEmployer,
    attend_job_fair: AttendJobFair,
    participate_in_resea: Resea,
  };
  const ActiveForm = activity ? formsDict[activity] : null;

  if (isFetching) {
    return (
      <Box mt={6} mb={2.5} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ py: 3.75 }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { md: 'space-between' },
            alignItems: { md: 'center' },
          }}
        >
          <Typography variant="h1">{intl.formatMessage({ id: 'jobSearchActivity.title' })}</Typography>
          <Box pb={!isSmall ? 5 : 1} />
        </Box>
        <Divider sx={{ mt: 2 }} />

        <Box
          sx={{
            backgroundColor: 'white',
            pt: 1.5,
            pb: 2.5,

            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ my: 1 }}>
            {intl.formatMessage(
              { id: 'uiWorkSearch.description1' },
              { weekStart: selectedClaim.weekStart, weekEnd: selectedClaim.weekEnd }
            )}
          </Typography>
          <Typography sx={{ mb: 1 }}>{intl.formatMessage({ id: 'uiWorkSearch.description2' })}</Typography>
          <Typography sx={{ mb: 1 }}>{intl.formatMessage({ id: 'uiWorkSearch.description3' })}</Typography>
          <Box
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pr: { md: 2.5 },
                width: { md: '50%' },
              }}
            >
              <Typography variant="h5" component="h2">
                {intl.formatMessage({ id: 'jobSearchActivity.youreRequired' })}
              </Typography>
              <Box component="ul" sx={{ pl: { xs: 2.2, md: 3 }, '& li:marker': { opacity: 0.5 } }}>
                <li>
                  <Typography variant="body2" gutterBottom>
                    {intl.formatMessage({ id: 'jobSearchActivity.applyForJob' })}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" gutterBottom>
                    <span>{intl.formatMessage({ id: 'jobSearchActivity.interviewWithEmployer' })}</span>
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" gutterBottom>
                    <span>{intl.formatMessage({ id: 'jobSearchActivity.attendJobFair' })}</span>
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" gutterBottom>
                    <span>{intl.formatMessage({ id: 'jobSearchActivity.participateInWorkshop' })}</span>
                  </Typography>
                </li>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  borderColor: grey['300'],
                  borderStyle: 'solid',
                  borderWidth: 1,
                  padding: '24px 35px 24px 30px',
                  boxSizing: 'border-box',
                  justifyContent: 'space-between',
                  '&:first-of-type': {
                    mb: 3,
                  },
                }}
              >
                <Box pr={2}>
                  <Typography
                    component="h3"
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {intl.formatMessage({ id: 'jobSearchActivity.requiredWeekly' })}
                    <br />
                    {formatDateRange(claimWeek?.from, claimWeek?.to)}
                  </Typography>
                  <Typography sx={{ fontSize: 15 }}>
                    {intl.formatMessage(
                      { id: 'jobSearchActivity.completedTasks' },
                      {
                        completed:
                          claimWeek?.items?.length > claimWeek?.required
                            ? claimWeek?.required
                            : claimWeek?.items?.length,
                        total: claimWeek?.required,
                      }
                    )}
                  </Typography>
                </Box>
                <Box sx={{ width: 111, height: 111 }}>
                  <CircularProgressbarWithChildren
                    value={completedCurrentWeek > 100 ? 100 : completedCurrentWeek}
                    strokeWidth={18}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      pathColor: completedCurrentWeek >= 100 ? palette.primary.main : '#FCCB00',
                      backgroundColor: '#B7B7B7',
                    })}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        fontSize: { xs: 18, md: 24 },
                        fontWeight: 700,
                      }}
                    >
                      {claimWeek?.items?.length > claimWeek?.required ? claimWeek?.required : claimWeek?.items?.length}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: { xs: 12, md: 13 },
                        lineHeight: '18px',
                      }}
                    >
                      {intl.formatMessage({ id: 'jobSearchActivity.of' })} {ACTIVITIES_REQUIRED}
                    </Typography>
                  </CircularProgressbarWithChildren>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  flexDirection: 'column',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: grey['300'],
                  padding: '19px 35px 24px 30px',
                  boxSizing: 'border-box',
                  justifyContent: 'space-between',
                  '&:first-of-type': {
                    marginBottom: 3,
                  },
                }}
              >
                <Typography variant="h2" component="h3" sx={{ textAlign: 'center', fontSize: 18, mb: 1.3 }}>
                  {intl.formatMessage({ id: 'jobSearchActivity.requiredWeekly' })}
                  <br />
                  {formatDateRange('2024-09-08', '2024-09-14')}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box sx={{ width: 111, height: 111 }}>
                    <CircularProgressbarWithChildren
                      value={completedCurrentWeek > 100 ? 100 : completedCurrentWeek}
                      strokeWidth={18}
                      styles={buildStyles({
                        strokeLinecap: 'butt',
                        pathColor: completedCurrentWeek >= 100 ? palette.primary.main : '#FCCB00',
                        backgroundColor: '#B7B7B7',
                      })}
                    >
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          fontSize: { xs: 18, md: 24 },
                          fontWeight: 700,
                        }}
                      >
                        {claimWeek?.items?.length}
                      </Typography>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: 13,
                          lineHeight: '18px',
                        }}
                      >
                        {intl.formatMessage({ id: 'jobSearchActivity.of' })} {ACTIVITIES_REQUIRED}
                      </Typography>
                    </CircularProgressbarWithChildren>
                  </Box>
                </Box>
                <Typography sx={{ fontSize: 15, mt: 2, textAlign: 'center' }}>
                  {intl.formatMessage(
                    { id: 'jobSearchActivity.completedTasks' },
                    {
                      completed: claimWeek?.items?.length,
                      total: claimWeek?.required,
                    }
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Activities */}
        <Divider sx={{ mb: 1, mt: 0.5 }} />
        <Box mb={5} />
        <Typography variant="h4" component="h2" style={{ display: 'flex' }}>
          {activityString(claimWeek?.from, claimWeek?.to)}{' '}
          <Tooltip
            enterTouchDelay={0}
            title={
              <Fragment>
                <span style={{ fontSize: 14 }}>
                  {intl.formatMessage({ id: 'jobSearchActivity.activity.weekTooltip' })}
                </span>
              </Fragment>
            }
            placement="top"
            arrow
            classes={tooltipClasses}
          >
            <Box ml={1}>
              <img
                alt="Info"
                src={infoSvg}
                style={{
                  width: '15px',
                  height: '15px',
                  cursor: 'pointer',
                }}
              />
            </Box>
          </Tooltip>
        </Typography>
        <Typography variant="body2">
          {intl.formatMessage(
            { id: 'jobSearchActivity.tasksCompleted' },
            {
              completed: claimWeek?.items?.length,
              total: claimWeek?.required,
            }
          )}
        </Typography>
        <Box
          sx={{
            mt: 2.5,
            mb: 3,
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr', lg: '1fr 1fr 1fr 1fr' },
              zIndex: 1,
              //absolute
            }}
          >
            {claimWeek?.items?.map((item) => (
              <SearchActivityCard
                key={item.worksearch_id}
                type={item.state}
                item={item}
                date={formatDate(item.activity_date)}
                onClick={() => {
                  setActivity(item.activity_type);
                  setItemToEdit(item);
                  setShowActivityBox(true);
                }}
                refreshCounter={() => {}}
                setRefreshCounter={() => {}}
                saving={item.saving}
              />
            ))}
            <SearchActivityCard
              type="ADD_NEW"
              saving={Boolean(itemToEdit)}
              title={`[${intl.formatMessage({ id: 'jobSearchActivity.addContactInformation' })}]`}
              onClick={() => {
                setItemToEdit(null);
                setShowActivityBox(true);
              }}
            />
          </Box>
          {showNewActivityBox && (
            <Box sx={{ mt: 4, py: 2, border: '1px solid lightgray', borderRadius: '4px', boxSizing: 'border-box' }}>
              <Typography variant="h4" component="h2" sx={{ px: 3 }}>
                {intl.formatMessage({ id: 'uiWorkSearch.addNew' })}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ px: 3, py: 2 }}>
                <FormControl
                  variant="filled"
                  sx={{
                    width: { xs: '100% !important', md: '408px !important' },
                  }}
                >
                  <InputLabel id="select-activity-type-label">
                    {intl.formatMessage({ id: 'jobSearchActivity.chooseActivity' })}
                  </InputLabel>
                  <Select
                    labelId="select-activity-type-label"
                    value={activity}
                    disabled={Boolean(itemToEdit)}
                    onChange={({ target: { value } }) => setActivity(value)}
                    label={intl.formatMessage({ id: 'jobSearchActivity.chooseActivity' })}
                    MenuProps={{
                      sx: {
                        maxWidth: 200,
                      },
                    }}
                    inputProps={{
                      title: intl.formatMessage({ id: 'jobSearchActivity.chooseActivity' }),
                    }}
                  >
                    {Object.keys(editActivityOptions).map((option) => (
                      <MenuItem key={option} value={option}>
                        <Box
                          sx={{
                            color: grey['800'],
                            fontSize: 13,
                            lineHeight: '18px',
                            whiteSpace: 'break-spaces',
                          }}
                          component="span"
                        >
                          {editActivityOptions[option]}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box
                  component="section"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    mt: 2,
                  }}
                >
                  {activity ? (
                    <ActiveForm
                      addActivityItem={() => {}}
                      updateActivityItem={() => {}}
                      setGeneralActivity={() => {}}
                      selectedActivity={itemToEdit ?? null}
                      setOpen={() => {
                        setItemToEdit(null);
                        setShowActivityBox(false);
                      }}
                      editting={false}
                      week={{
                        from: claimDateToIso(selectedClaim.weekStart),
                        to: claimDateToIso(selectedClaim.weekEnd),
                      }}
                      onSubmit={(values) => {
                        setShowActivityBox(false);
                        addActivityMutation.mutate(values);
                      }}
                    />
                  ) : null}
                </Box>
              </Box>
            </Box>
          )}
          <Box mt={3}>
            <Button variant="contained" onClick={handleContinue}>
              {intl.formatMessage({ id: 'uiWorkSearch.continue' })}
            </Button>
          </Box>
        </Box>
      </Container>
      <ConfirmationDialog
        open={confirmationOpen}
        title={intl.formatMessage({ id: 'uiWorkSearch.dialogTitle' })}
        text={intl.formatMessage({ id: 'uiWorkSearch.dialogText' })}
        onCancel={() => setConfirmationOpen(false)}
        onConfirm={() => history.push(`${urlPrefix}/file-a-claim`)}
        cancelLabel={intl.formatMessage({ id: 'jobSearchActivity.deletePopup.cancel' })}
        confirmLabel={intl.formatMessage({ id: 'uiWorkSearch.dialogContinue' })}
      />
    </Box>
  );
};

export default UIWorkSearchActivity;

import { type z } from 'zod';
import { api, HTTPMethod, EmptyObjectSchema } from './client';
import {
  GetNotificationsRequestSchema,
  GetNotificationsResponseSchema,
  UpdateNotificationRequestSchema,
} from 'lib/schemas/notifications';

export const getNotifications = api<
  z.infer<typeof GetNotificationsRequestSchema>,
  z.infer<typeof GetNotificationsResponseSchema>
>({
  method: HTTPMethod.GET,
  path: '/users/me/notifications/',
  requestSchema: GetNotificationsRequestSchema,
  responseSchema: GetNotificationsResponseSchema,
});

export const updateNotificationRead = api<
  z.infer<typeof UpdateNotificationRequestSchema>,
  z.infer<typeof EmptyObjectSchema>
>({
  method: HTTPMethod.PUT,
  path: '/users/me/notifications/',
  requestSchema: UpdateNotificationRequestSchema,
  responseSchema: EmptyObjectSchema,
});

import { useState, useEffect, Fragment } from 'react';
import { pathOr, propOr, equals } from 'ramda';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useUpdateUser } from 'shared/src/lib/hooks/queries/userQueries';
import { useHistory } from 'react-router-dom';
import { grey } from '@mui/material/colors';

/* Form */
import { Formik, useFormik } from 'formik';
import { PhoneNumberInput } from 'shared/components';
import ZipcodeInput from 'components/inputs/ZipcodeInput';
import { DateInput } from 'shared/components';

/* i18n */
import { useIntl } from 'react-intl';

/* Assets */
import info from 'assets/images/Info_Cards.svg';

/* API */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { updateUserData, updateExperience } from 'shared/src/lib/API';
import { apiRequest } from 'lib/API';

/* Styles */
import recStyles from 'styles/recommendations-list.module.scss';
import cssVars from 'styles/vars.module.scss';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import {
  Container,
  Typography,
  Divider,
  Box,
  Chip,
  TextField,
  Button,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Tooltip,
  useMediaQuery,
  Stack,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from '@mui/material';

/* Redux */
import { useDispatch } from 'react-redux';
import { setOpenContactInfo } from 'app/slices/experienceSlice';
import { setZipcode } from 'app/slices/experienceSlice';
import { setPreferredName } from 'app/slices/appSlice';

/* UI Components */
import { useSnackbar } from 'notistack';
import { LoadingSmall } from 'components/Loading';

import { CustomNotification, LeavingPageDialog } from 'shared/components';
import ToggleSwitch from 'shared/src/components/ToggleSwitch';
import { useInfiniteRatings } from 'shared/hooks';

/* Utils */
import validZipCodes from 'lib/validZipCodes.json';
import { preventSubmitOnEnter } from 'shared/utils';
import { trackEvent } from 'lib/analytics';

const Profile = () => {
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const updateUser = useUpdateUser();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [choseToStay, setChoseToStay] = useState(false);
  const ratingsQuery = useInfiniteRatings({ limit: 2000 });
  const allSaved = ratingsQuery.isSuccess ? ratingsQuery.data.pages.flat(1).filter((r) => r.rating === 1) : [];
  const [submitting, setSubmitting] = useState(false);

  /*-- initial load --*/
  const experienceQuery = useQuery({
    queryKey: ['/users/me/experience/'],
    refetchOnMount: 'always',
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
  const contactQuery = useQuery({
    queryKey: ['/users/me'],
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
  const citiesQuery = useQuery({
    queryKey: ['/cities/'],
  });
  const locationQuery = useQuery({
    queryKey: ['/users/me/location/'],
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: citiesQuery.isSuccess,
  });

  const citiesDict = citiesQuery.data?.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {});

  const initialValues = {
    current_city: citiesDict ? citiesDict[locationQuery.data?.current_city ?? ''] : null,
    desired_commute_mi: locationQuery.data?.desired_commute_mi ?? '',
    willing_to_relocate: locationQuery.data?.willing_to_relocate ? 'yes' : 'no',
    alternate_cities: citiesDict ? locationQuery.data?.alternate_cities.map((id) => citiesDict[id]) : [],
  };

  const experiences = pathOr([], ['data', 'job_experience'], experienceQuery);
  const skillsCount = experiences.reduce((acc, curr) => {
    if (curr.skills?.length) {
      return acc + curr.skills.length;
    }
    return acc;
  }, 0);

  /*-- work experience --*/
  const [loadedWorkHistory, setLoadedWorkHistory] = useState(false);

  const [contactHasChanged, setContactHasChanged] = useState(false);

  useEffect(() => {
    if (!experienceQuery.isFetching && experienceQuery.isSuccess) {
      setLoadedWorkHistory(true);
    }
  }, [experienceQuery.isSuccess, experienceQuery.isFetching]);

  /*-- analytics --*/
  useEffect(() => {
    document.title = intl.formatMessage({ id: 'profile.title' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('PROFILE');
    TimeMe.startTimer();
    return () => {
      dispatch(setOpenContactInfo(false));
    };
  }, []);

  return (
    <>
      <LeavingPageDialog
        when={contactHasChanged}
        onOK={() => true}
        onCancel={() => false}
        setChoseToStay={setChoseToStay}
      />
      <div className={recStyles.content}>
        <Container maxWidth="lg">
          {experienceQuery.isLoading ||
          experienceQuery.isFetching ||
          !loadedWorkHistory ||
          contactQuery.isLoading ||
          contactQuery.isFetching ? (
            <LoadingSmall />
          ) : (
            <div>
              <Box>
                <Typography variant="h1">{intl.formatMessage({ id: 'profile.title' })}</Typography>
                <Box>
                  <Chip
                    label={`${skillsCount} ${intl.formatMessage({
                      id: skillsCount === 1 ? 'profile.skillsCountSingular' : 'profile.skillsCountPlural',
                    })}`}
                    color="primary"
                    sx={{ mr: 1, mb: { xs: 0.3, sm: 0 } }}
                  />
                  <Chip
                    label={`${experiences.length} ${intl.formatMessage({
                      id: experiences.length === 1 ? 'profile.experiencesCountSingular' : 'profile.experiencesPlural',
                    })}`}
                    color="primary"
                    sx={{ mr: 1, mb: { xs: 0.3, sm: 0 } }}
                  />
                  {allSaved.length ? (
                    <Chip
                      label={`${allSaved.length} ${intl.formatMessage({ id: 'layout.menu.savedItems' })}`}
                      color="primary"
                      sx={{ mb: { xs: 0.3, sm: 0 } }}
                    />
                  ) : null}
                </Box>
              </Box>

              <Divider sx={{ mt: 2, mb: 1 }} />
              <Typography variant="h4" component="h2" pt={2} pb={1}>
                {intl.formatMessage({ id: 'profile.aboutMe.title' })}
              </Typography>
              <Typography variant="body1" component="p">
                {intl.formatMessage({ id: 'profile.subtitle' })}
              </Typography>
              <Box m={3} />

              <Box mt={1} style={{ minWidth: 300 }}>
                <ContactForm
                  initialValues={{ ...contactQuery.data, degree: `${experienceQuery.data?.education[0].degree}` }}
                  valueChanged={(hasItChanged) => setContactHasChanged(hasItChanged)}
                  choseToStay={choseToStay}
                  classNames={recStyles.educationForm}
                  submitLabel={intl.formatMessage({ id: 'jobSearchActivity.form.save' })}
                  validZipCodes={validZipCodes}
                  state="Arkansas"
                  submitCallback={() => {
                    enqueueSnackbar('', {
                      variant: 'default',
                      content: (key) => (
                        <CustomNotification id={key} check>
                          {intl.formatMessage({ id: 'experience.changesSaved' })}
                        </CustomNotification>
                      ),
                    });
                  }}
                />

                <Typography sx={{ mt: 3 }} variant="h1">
                  {intl.formatMessage({ id: 'profile.myPreferences.title' })}
                </Typography>

                <Divider sx={{ mt: 2, mb: 1 }} />

                <Box mb={2}>
                  <FormLabel htmlFor="employer-match-switch">
                    <Typography variant="h4" component="h2" pt={2} pb={1}>
                      {intl.formatMessage({ id: 'profile.optIn.title' })}
                    </Typography>
                  </FormLabel>
                  <Typography variant="body1" component="p" sx={{ a: { color: 'primary.dark' }, mb: { xs: 1, md: 0 } }}>
                    {intl.formatMessage(
                      { id: 'profile.optIn.p1' },
                      {
                        experienceLink: (
                          <Link to={`${urlPrefix}/experience`}>
                            {intl.formatMessage({ id: 'layout.menu.experience' })}
                          </Link>
                        ),
                      }
                    )}
                  </Typography>
                  <Typography variant="body1" component="p" sx={{ mb: { xs: 1, md: 0 } }}>
                    {intl.formatMessage({ id: 'profile.optIn.p2' })}
                  </Typography>
                  <Typography variant="body1" component="p" sx={{ a: { color: 'primary.dark' }, mb: { xs: 1, md: 0 } }}>
                    {intl.formatMessage(
                      { id: 'profile.optIn.p3' },
                      {
                        termsLink: (
                          <Link to={`${urlPrefix}/terms-of-use`}>
                            {intl.formatMessage({ id: 'layout.footer.privacy' })}
                          </Link>
                        ),
                      }
                    )}
                  </Typography>
                  <Typography variant="body1" component="p">
                    {intl.formatMessage({ id: 'profile.optIn.p4' })}
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1.5 }}>
                    <Typography>{intl.formatMessage({ id: 'profile.matchFeature.off' })}</Typography>
                    <ToggleSwitch
                      checked={
                        !contactQuery.isLoading && !contactQuery.isFetching
                          ? pathOr(false, ['data', 'user_match_feature'], contactQuery)
                          : false
                      }
                      onChange={({ target: { checked } }) => {
                        updateUser.mutate(
                          { user_match_feature: checked },
                          {
                            onSuccess: () => {
                              enqueueSnackbar('', {
                                variant: 'default',
                                content: (key) => (
                                  <CustomNotification id={key} check>
                                    {intl.formatMessage(
                                      {
                                        id: checked ? 'profile.matchFeature.optedIn' : 'profile.matchFeature.optedOut',
                                      },
                                      {
                                        link: (
                                          <Typography
                                            component="span"
                                            sx={{
                                              pb: 0.5,
                                              '&:active': { color: grey[100] },
                                              fontSize: '0.875rem',
                                              cursor: 'pointer',
                                              textDecoration: 'underline',
                                            }}
                                            onClick={() => history.push(`${urlPrefix}/my-profile`)}
                                          >
                                            {intl.formatMessage({ id: 'recommendations.updateContactInfo' })}
                                          </Typography>
                                        ),
                                      }
                                    )}
                                  </CustomNotification>
                                ),
                              });
                            },
                          }
                        );
                      }}
                      id="employer-match-switch"
                    />
                    <Typography>{intl.formatMessage({ id: 'profile.matchFeature.on' })}</Typography>
                  </Stack>
                </Box>

                <Box mb={2}>
                  <FormLabel htmlFor="email-switch">
                    <Typography variant="h4" component="h2" pt={2} pb={1}>
                      {intl.formatMessage({ id: 'profile.emailSettings.title' })}
                    </Typography>
                  </FormLabel>
                  <Typography variant="body1" component="p">
                    {intl.formatMessage({ id: 'profile.emailSettings.subtitle' })}
                  </Typography>

                  <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1.5 }}>
                    <Typography>{intl.formatMessage({ id: 'profile.matchFeature.off' })}</Typography>
                    <ToggleSwitch
                      checked={
                        !contactQuery.isLoading && !contactQuery.isFetching
                          ? pathOr(false, ['data', 'bulk_email_enabled'], contactQuery)
                          : false
                      }
                      onChange={({ target: { checked } }) => {
                        updateUser.mutate({ bulk_email_enabled: checked });
                      }}
                      id="email-switch"
                    />
                    <Typography>{intl.formatMessage({ id: 'profile.matchFeature.on' })}</Typography>
                  </Stack>
                </Box>

                <Box
                  mb={2}
                  sx={{
                    maxWidth: 600,
                  }}
                >
                  <Typography variant="h4" component="h2" pt={2} pb={1}>
                    {intl.formatMessage({ id: 'profile.location.title' })}
                  </Typography>

                  <Typography variant="h5" component="h3" pb={1}>
                    {intl.formatMessage({ id: 'profile.location.currentLocationPreferences' })}
                  </Typography>

                  <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validate={(values) => {
                      const errors = {};
                      if (!values.current_city) {
                        errors.current_city = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
                      }
                      if (!values.desired_commute_mi && !Number.isInteger(values.desired_commute_mi)) {
                        errors.desired_commute_mi = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
                      }
                      if (!values.willing_to_relocate) {
                        errors.willing_to_relocate = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
                      }
                      if (values.willing_to_relocate === 'yes' && !values.alternate_cities?.length) {
                        errors.alternate_cities = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
                      }
                      return errors;
                    }}
                    onSubmit={(values) => {
                      setSubmitting(true);
                      const valuesToSubmit = {
                        current_city: values.current_city.id,
                        ...(Number.isInteger(Number(values.desired_commute_mi))
                          ? { desired_commute_mi: Number(values.desired_commute_mi) }
                          : {}),
                        ...(values.willing_to_relocate
                          ? { willing_to_relocate: values.willing_to_relocate === 'yes' }
                          : {}),
                        ...(values.alternate_cities?.length
                          ? { alternate_cities: values.alternate_cities.map(({ id }) => id) }
                          : {}),
                      };
                      apiRequest('PUT', '/users/me/location/', {
                        data: valuesToSubmit,
                      })
                        .then(() => {
                          setSubmitting(false);
                          queryClient.invalidateQueries({ queryKey: ['/users/me/location/'] });
                          enqueueSnackbar('', {
                            variant: 'default',
                            content: (key) => (
                              <CustomNotification id={key} check>
                                {intl.formatMessage({ id: 'experience.changesSaved' })}
                              </CustomNotification>
                            ),
                          });
                        })
                        .catch(() => {
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                      <Box component="form" onSubmit={handleSubmit}>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: { xs: 1, md: 3 },
                            mb: 1,
                            flexDirection: { xs: 'column', md: 'row' },
                          }}
                        >
                          <Autocomplete
                            id="where-located-onboard-dropdown"
                            fullWidth
                            options={citiesQuery?.data ?? []}
                            getOptionLabel={({ city }) => city}
                            getOptionKey={({ id }) => id}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={values.current_city ?? null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                label={
                                  <>
                                    {intl.formatMessage({ id: 'profile.location.form.cityLabel' })}
                                    <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
                                  </>
                                }
                                helperText={touched.current_city && errors.current_city ? errors.current_city : ''}
                                error={touched.current_city && Boolean(errors.current_city)}
                                id="where-located-onboard-dropdown"
                              />
                            )}
                            sx={{
                              '& .MuiInputBase-root': {
                                paddingTop: '18px!important',
                              },
                              '& .MuiAutocomplete-input': {
                                fontSize: 12,
                              },
                            }}
                            onChange={(_, value) => setFieldValue('current_city', value)}
                          />

                          {/* Distance */}
                          <FormControl
                            fullWidth
                            error={!!touched.desired_commute_mi && !!errors.desired_commute_mi}
                            variant="filled"
                          >
                            <InputLabel id="travel-distance-label" htmlFor="travel-for-work-input-id">
                              {intl.formatMessage({ id: 'profile.location.form.distanceLabel' })}
                              <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
                            </InputLabel>
                            <Select
                              labelId="travel-distance-label"
                              value={values.desired_commute_mi}
                              onChange={(evt) => setFieldValue('desired_commute_mi', evt.target.value)}
                              id="travel-for-work-onboard-dropdown"
                              inputProps={{
                                id: 'travel-for-work-input-id',
                              }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              onKeyDown={preventSubmitOnEnter}
                            >
                              <MenuItem value="0">
                                <em>{intl.formatMessage({ id: 'onboarding.location.myCityOnly' })}</em>
                              </MenuItem>
                              <MenuItem value="1" id="travel-for-work-1-mile-dropdown">
                                {intl.formatMessage({ id: 'onboarding.location.within' })} 1{' '}
                                {intl.formatMessage({ id: 'onboarding.location.mile' })} (1.6km)
                              </MenuItem>
                              <MenuItem value="5" id="travel-for-work-5-miles-dropdown">
                                {intl.formatMessage({ id: 'onboarding.location.within' })} 5{' '}
                                {intl.formatMessage({ id: 'onboarding.location.miles' })} (8km)
                              </MenuItem>
                              <MenuItem value="10" id="travel-for-work-10-miles-dropdown">
                                {intl.formatMessage({ id: 'onboarding.location.within' })} 10{' '}
                                {intl.formatMessage({ id: 'onboarding.location.miles' })} (16km)
                              </MenuItem>
                              <MenuItem value="25" id="travel-for-work-25-miles-dropdown">
                                {intl.formatMessage({ id: 'onboarding.location.within' })} 25{' '}
                                {intl.formatMessage({ id: 'onboarding.location.miles' })} (40km)
                              </MenuItem>
                              <MenuItem value="50" id="travel-for-work-50-miles-dropdown">
                                {intl.formatMessage({ id: 'onboarding.location.within' })} 50{' '}
                                {intl.formatMessage({ id: 'onboarding.location.miles' })} (80km)
                              </MenuItem>
                              <MenuItem value="100" id="travel-for-work-100-miles-dropdown">
                                {intl.formatMessage({ id: 'onboarding.location.within' })} 100{' '}
                                {intl.formatMessage({ id: 'onboarding.location.miles' })} (160km)
                              </MenuItem>
                              <MenuItem value="200" id="travel-for-work-200-miles-dropdown">
                                {intl.formatMessage({ id: 'onboarding.location.within' })} 200{' '}
                                {intl.formatMessage({ id: 'onboarding.location.miles' })} (321km)
                              </MenuItem>
                            </Select>
                            {!!touched.desired_commute_mi && !!errors.desired_commute_mi && (
                              <FormHelperText>
                                {errors.desired_commute_mi ? intl.formatMessage({ id: errors.desired_commute_mi }) : ''}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                        <Typography variant="h5" component="h3" pb={1}>
                          {intl.formatMessage({ id: 'profile.location.relocationPreferences' })}
                        </Typography>

                        {/* Willing to relocate */}
                        <FormControl
                          sx={{ mb: 1 }}
                          error={!!touched.willing_to_relocate && !!errors.willing_to_relocate}
                        >
                          <FormLabel component="legend">
                            {intl.formatMessage({ id: 'onboarding.location.willingToRelocate' })}
                            <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
                          </FormLabel>
                          <RadioGroup
                            aria-label={intl.formatMessage({ id: 'onboarding.location.willingToRelocate' })}
                            name="willing_to_relocate"
                            value={values.willing_to_relocate}
                            onChange={(event) => {
                              const value = event.target.value;
                              setFieldValue('willing_to_relocate', value);
                              if (value === 'no') {
                                setFieldValue('alternate_cities', []);
                              }
                            }}
                            row
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio color="primary" id="yes-relocate-onboard-radio-button" />}
                              label={intl.formatMessage({ id: 'jobSearchActivity.form.yes' })}
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio color="primary" id="no-relocate-onboard-radio-button" />}
                              label={intl.formatMessage({ id: 'jobSearchActivity.form.no' })}
                            />
                          </RadioGroup>
                          {!!touched.willing_to_relocate && !!errors.willing_to_relocate && (
                            <FormHelperText sx={{ left: 0, right: 'auto' }}>
                              {errors.willing_to_relocate ? intl.formatMessage({ id: errors.willing_to_relocate }) : ''}
                            </FormHelperText>
                          )}
                        </FormControl>

                        {/* Cities to relocate */}
                        <Box
                          sx={{
                            display: values.willing_to_relocate === 'yes' ? 'flex' : 'none',
                            flexDirection: 'column',
                          }}
                        >
                          <Autocomplete
                            multiple
                            id="relocation-city-onboard-dropdown"
                            options={citiesQuery?.data ?? []}
                            getOptionLabel={({ city }) => city}
                            getOptionKey={({ id }) => id}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={values.alternate_cities}
                            renderTags={(value, getTagProps) => {
                              return (
                                <div>
                                  {value.map((option, index) => {
                                    const { className, ...rest } = getTagProps({ index }); //eslint-disable-line

                                    return (
                                      <Chip
                                        key={option}
                                        size="small"
                                        label={value[index].city}
                                        {...rest}
                                        sx={{ maxWidth: 180, px: 0, my: 0.5 }}
                                      />
                                    );
                                  })}
                                </div>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                label={
                                  <>
                                    {intl.formatMessage({ id: 'profile.location.form.relocationCity' })}
                                    <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
                                  </>
                                }
                                id="relocation-city-onboard-dropdown"
                                helperText={
                                  touched.alternate_cities && errors.alternate_cities ? errors.alternate_cities : ''
                                }
                                error={touched.alternate_cities && Boolean(errors.alternate_cities)}
                              />
                            )}
                            onChange={(_, value) => setFieldValue('alternate_cities', value)}
                            sx={{
                              '& .MuiInputBase-root': {
                                paddingTop: '18px!important',
                              },
                              '& .MuiAutocomplete-input': {
                                fontSize: 12,
                              },
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            color={'secondary'}
                            disabled={submitting}
                            size="large"
                            type="submit"
                            variant="contained"
                            id="next-onboard-step-5-button"
                          >
                            {submitting ? (
                              <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
                              </div>
                            ) : (
                              intl.formatMessage({ id: 'profile.location.form.save' })
                            )}
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Formik>
                </Box>
              </Box>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

function ContactForm({
  classNames,
  submitLabel,
  valueChanged = () => {},
  choseToStay,
  validZipCodes,
  initialValues: defaultValues,
  state = 'Wisconsin',
  submitCallback = () => {},
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isSmall = useMediaQuery('(max-width:959px)');

  const educationOptions = [
    { value: '11', title: intl.formatMessage({ id: 'forms.onboarding.education.noDiploma' }) },
    { value: '11.5', title: intl.formatMessage({ id: 'forms.onboarding.education.someHighSchool' }) },
    { value: '12', title: intl.formatMessage({ id: 'forms.onboarding.education.diploma' }) },
    { value: '13', title: intl.formatMessage({ id: 'forms.onboarding.education.someCollege' }) },
    { value: '14', title: intl.formatMessage({ id: 'forms.onboarding.education.assocDegree' }) },
    { value: '16', title: intl.formatMessage({ id: 'forms.onboarding.education.bachDegree' }) },
    { value: '16.5', title: intl.formatMessage({ id: 'forms.onboarding.education.someGraduate' }) },
    { value: '17', title: intl.formatMessage({ id: 'forms.onboarding.education.mastersDegree' }) },
    { value: '20', title: intl.formatMessage({ id: 'forms.onboarding.education.advancedDegree' }) },
  ];

  const lettersNumbers = /^[^<>]+$/; // eslint-disable-line no-useless-escape
  //eslint-disable-next-line
  const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const emailRegExp = /^\S+@\S+\.\w\w+$/;
  const validationSchema = yup.object({
    first_name: yup
      .string('forms.onboarding.firstNameValidation')
      .min(2, 'forms.onboarding.firstNameMinLength')
      .max(50, 'forms.onboarding.firstNameMaxLength')
      .matches(lettersNumbers, 'profile.validation.basicPunctiation')
      .required('forms.onboarding.firstNameRequired'),
    last_name: yup
      .string('forms.onboarding.lastNameValidation')
      .min(2, 'forms.onboarding.lastNameMinLength')
      .max(50, 'forms.onboarding.lastNameMaxLength')
      .matches(lettersNumbers, 'profile.validation.basicPunctiation')
      .required('forms.onboarding.lastNameRequired'),
    email: yup
      .string('forms.onboarding.emailRequired')
      .matches(emailRegExp, 'forms.onboarding.emailValidation')
      .required('forms.onboarding.emailRequired'),
    dob: yup
      .date('forms.onboarding.dateOfBirthValidation')
      .typeError('forms.onboarding.dateOfBirthInvalid')
      .min('01/01/1900', 'forms.onboarding.dateOfBirthTooOld')
      .max(new Date(), 'forms.onboarding.dateOfBirthTooNew')
      .required('forms.onboarding.dateOfBirthRequired'),
    phone_number: yup.string('forms.onboarding.phoneNumber').matches(phoneRegExp, 'forms.onboarding.exactlyXDigits'),
    zipcode: yup
      .string('forms.onboarding.zipcode')
      .matches(/^[0-9]+$/, 'forms.onboarding.onlyDigits')
      .oneOf(
        validZipCodes,
        intl.formatMessage(
          {
            id: 'training.filters.zipcodeFromStateValidation',
          },
          { state }
        )
      )
      .min(5, 'forms.onboarding.exactlyXDigits')
      .max(5, 'forms.onboarding.exactlyXDigits'),
  });

  const initialValues = {
    first_name: propOr('', 'first_name', defaultValues),
    last_name: propOr('', 'last_name', defaultValues),
    email: propOr('', 'email', defaultValues),
    dob: propOr('', 'dob', defaultValues),
    phone_number: propOr('', 'phone_number', defaultValues),
    zipcode: propOr('', 'zipcode', defaultValues),
    degree: propOr('', 'degree', defaultValues),
  };

  const [keysWithChanges, setKeysWithChanges] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [savingDegree, setSavingDegree] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async ({ first_name, last_name, dob, phone_number, zipcode, degree }) => {
      setSubmitting(true);
      let valuesToSubmit = {
        first_name,
        last_name,
        dob,
        phone_number,
        zipcode,
        preferred_name: first_name || '',
      };
      if (degree) {
        setSavingDegree(true);
        updateExperience({ education: [{ degree }] })
          .then(() => {
            submitCallback();
            setSavingDegree(false);
          })
          .catch(() => {
            setSavingDegree(false);
          });
      }

      updateUserData(valuesToSubmit)
        .then(function () {
          dispatch(setPreferredName(first_name));
          dispatch(setZipcode(zipcode));
          setSubmitting(false);
        })
        .catch(function () {
          setSubmitting(false);
        });
    },
  });

  const [initialFormikValues, setInitialFormikValues] = useState({ ...formik.values });

  useEffect(() => {
    if (!formik.values.empty && initialFormikValues.empty) {
      setInitialFormikValues({ ...formik.values });
    }
  }, [formik.values]);

  const handleDiffs = (fieldName, evt) => {
    const initialValues = initialFormikValues;
    const currentValues = { ...formik.values, [fieldName]: evt.target.value };
    if (equals(initialValues, currentValues)) {
      setKeysWithChanges([]);
      valueChanged(false);
    } else {
      const differentKeys = [];
      for (const key in initialValues) {
        if (initialValues[key] !== currentValues[key]) {
          differentKeys.push(key);
        }
      }
      setKeysWithChanges(differentKeys);
      valueChanged(true);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        trackEvent('PROFILE_SAVE');
        formik.handleSubmit(e);
        setInitialFormikValues({ ...formik.values });
        valueChanged(false);
        setKeysWithChanges([]);
      }}
      className={classNames}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'auto', md: '1fr 1fr' },
          gap: { xs: '20px', md: '16px 40px' },
        }}
      >
        <TextField
          sx={{
            '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
          }}
          error={
            (formik.touched.first_name && Boolean(formik.errors.first_name)) ||
            (choseToStay && keysWithChanges.includes('first_name'))
          }
          fullWidth
          helperText={
            formik.touched.first_name &&
            formik.errors.first_name &&
            intl.formatMessage({ id: formik.errors.first_name })
          }
          label={
            <>
              {intl.formatMessage({ id: 'forms.onboarding.firstName' })}
              <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
            </>
          }
          name="first_name"
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
            handleDiffs('first_name', e);
          }}
          onKeyDown={preventSubmitOnEnter}
          type="text"
          value={formik.values.first_name}
          variant="filled"
          inputProps={{
            title: intl.formatMessage({ id: 'forms.onboarding.firstName' }),
          }}
        />

        <TextField
          sx={{
            '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
          }}
          error={
            (formik.touched.last_name && Boolean(formik.errors.last_name)) ||
            (choseToStay && keysWithChanges.includes('last_name'))
          }
          fullWidth
          helperText={
            formik.touched.last_name && formik.errors.last_name && intl.formatMessage({ id: formik.errors.last_name })
          }
          label={
            <>
              {intl.formatMessage({ id: 'forms.onboarding.lastName' })}
              <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
            </>
          }
          name="last_name"
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
            handleDiffs('last_name', e);
          }}
          onKeyDown={preventSubmitOnEnter}
          type="text"
          value={formik.values.last_name}
          variant="filled"
          inputProps={{
            title: intl.formatMessage({ id: 'forms.onboarding.lastName' }),
          }}
        />
        {/*e-mail */}
        <Box sx={{ position: 'relative' }}>
          <TextField
            sx={{
              '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
            }}
            error={
              (formik.touched.email && Boolean(formik.errors.email)) ||
              (choseToStay && keysWithChanges.includes('email'))
            }
            disabled
            fullWidth
            helperText={formik.touched.email && formik.errors.email && intl.formatMessage({ id: formik.errors.email })}
            label={
              <>
                {intl.formatMessage({ id: 'forms.signIn.username' })}
                <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
              </>
            }
            name="email"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handleDiffs('email', e);
            }}
            onKeyDown={preventSubmitOnEnter}
            type="text"
            value={formik.values.email}
            variant="filled"
            inputProps={{
              title: intl.formatMessage({ id: 'forms.signIn.username' }),
            }}
          />
          <Tooltip
            enterTouchDelay={0}
            title={
              <Fragment>
                <span style={{ fontSize: 14 }}>
                  {intl.formatMessage(
                    { id: `profile.tooltips.email` },
                    {
                      link: (
                        <a
                          href="https://login.gov"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'white', display: 'inline-block' }}
                        >
                          <Typography
                            sx={{
                              color: 'white',
                              fontSize: 14,
                              display: 'inline-block',
                              fontWeight: 500,
                              textDecoration: 'underline',
                            }}
                          >
                            login.gov
                          </Typography>
                        </a>
                      ),
                    }
                  )}
                </span>
                <span style={{ fontSize: 14, display: 'block', paddingBottom: 5, paddingTop: 3 }}>
                  {intl.formatMessage({ id: `profile.tooltips.email2` })}
                </span>
              </Fragment>
            }
            placement="top"
            arrow
          >
            <img
              alt={intl.formatMessage(
                { id: 'profile.infoAlt' },
                { field: intl.formatMessage({ id: 'forms.signIn.username' }) }
              )}
              src={info}
              style={{
                position: 'absolute',
                top: '14px',
                right: '-22px',
                width: '15px',
                height: '15px',
                cursor: 'pointer',
                display: isSmall ? 'none' : 'block',
              }}
            />
          </Tooltip>
          <Box sx={{ fontSize: 14, display: { xs: 'block', md: 'none' }, position: 'relative', top: -4 }}>
            {intl.formatMessage(
              { id: `profile.tooltips.email` },
              {
                link: (
                  <a
                    href="https://login.gov"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'inline-block' }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        display: 'inline-block',
                        fontWeight: 500,
                        textDecoration: 'underline',
                        color: 'primary.main',
                      }}
                    >
                      login.gov
                    </Typography>
                  </a>
                ),
              }
            )}
            <span style={{ display: 'block' }}>{intl.formatMessage({ id: `profile.tooltips.email2` })}</span>
          </Box>
        </Box>

        <TextField
          sx={{
            '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
          }}
          error={(formik.touched.dob && Boolean(formik.errors.dob)) || (choseToStay && keysWithChanges.includes('dob'))}
          name="dob"
          type="text"
          variant="filled"
          fullWidth
          helperText={formik.touched.dob && formik.errors.dob && intl.formatMessage({ id: formik.errors.dob })}
          autoComplete="off"
          label={
            <>
              {intl.formatMessage({ id: 'forms.onboarding.dateOfBirth' })}
              <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
            </>
          }
          value={formik.values.dob}
          onChange={(e) => {
            formik.handleChange(e);
            handleDiffs('dob', e);
          }}
          onKeyDown={preventSubmitOnEnter}
          onBlur={formik.handleBlur}
          InputProps={{
            inputComponent: DateInput,
          }}
          inputProps={{
            title: intl.formatMessage({ id: 'forms.onboarding.dateOfBirth' }),
          }}
        />
        {/* Education Level */}
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
          <FormControl variant="filled" error={!!formik.touched.degree && !!formik.errors.degree}>
            <InputLabel id="education-level-label" sx={{ top: 2 }} htmlFor="education-level">
              {intl.formatMessage({ id: 'forms.onboarding.educationLevel.titlecase' })}
              <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
            </InputLabel>
            <Select
              name="degree"
              labelId="education-level-label"
              id="education-level"
              inputProps={{
                id: 'education-level',
              }}
              value={formik.values.degree}
              onChange={(e) => {
                formik.handleChange(e);
                handleDiffs('degree', e);
              }}
              error={
                (formik.touched.degree && Boolean(formik.errors.degree)) ||
                (choseToStay && keysWithChanges.includes('degree'))
              }
              onKeyDown={preventSubmitOnEnter}
              MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                  variant: 'outlined',
                },
                sx: {
                  maxHeight: 440,
                  '& .MuiPaper-root': {
                    borderRight: '1px solid #ccc', // Set the border style and color
                    borderBottom: '1px solid #ccc', // Set the border style and color
                    borderLeft: '1px solid #ccc', // Set the border style and color
                  },
                },
              }}
              sx={{
                '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
              }}
            >
              {educationOptions.map((eo) => (
                <MenuItem key={eo.value} value={eo.value}>
                  {eo.title}
                </MenuItem>
              ))}
            </Select>
            {!!formik.touched.degree && !!formik.errors.degree && (
              <FormHelperText>
                {formik.errors.degree ? intl.formatMessage({ id: formik.errors.degree }) : ''}
              </FormHelperText>
            )}
          </FormControl>
          <Tooltip
            enterTouchDelay={0}
            title={
              <Fragment>
                <span style={{ fontSize: 14 }}>{intl.formatMessage({ id: `profile.tooltips.education` })}</span>
              </Fragment>
            }
            placement="top"
            arrow
          >
            <img
              alt={intl.formatMessage(
                { id: 'profile.infoAlt' },
                { field: intl.formatMessage({ id: 'forms.onboarding.educationLevel.titlecase' }) }
              )}
              src={info}
              style={{
                position: 'absolute',
                top: '14px',
                right: '-22px',
                width: '15px',
                height: '15px',
                cursor: 'pointer',
                display: isSmall ? 'none' : 'block',
              }}
            />
          </Tooltip>
          <Box sx={{ fontSize: 14, display: { xs: 'block', md: 'none' }, position: 'relative', top: -4 }}>
            {intl.formatMessage({ id: `profile.tooltips.education` })}
          </Box>
        </Box>
        {/** Phone */}
        <Box sx={{ position: 'relative' }}>
          <TextField
            sx={{
              '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
            }}
            error={
              (formik.touched.phone_number && Boolean(formik.errors.phone_number)) ||
              (choseToStay && keysWithChanges.includes('phone_number'))
            }
            name="phone_number"
            type="text"
            variant="filled"
            fullWidth
            helperText={
              formik.touched.phone_number &&
              formik.errors.phone_number &&
              intl.formatMessage({ id: formik.errors.phone_number }, { amount: 10 })
            }
            autoComplete="off"
            label={intl.formatMessage({ id: 'forms.onboarding.phoneNumber' })}
            value={formik.values.phone_number}
            onChange={(e) => {
              formik.handleChange(e);
              handleDiffs('phone_number', e);
            }}
            onKeyDown={preventSubmitOnEnter}
            onBlur={formik.handleBlur}
            InputProps={{
              inputComponent: PhoneNumberInput,
            }}
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.phoneNumber' }),
            }}
          />
          <Tooltip
            enterTouchDelay={0}
            title={
              <Fragment>
                <span style={{ fontSize: 14 }}>{intl.formatMessage({ id: `profile.tooltips.phone` })}</span>
              </Fragment>
            }
            placement="top"
            arrow
          >
            <img
              alt={intl.formatMessage(
                { id: 'profile.infoAlt' },
                { field: intl.formatMessage({ id: 'forms.onboarding.phoneNumber' }) }
              )}
              src={info}
              style={{
                position: 'absolute',
                top: '14px',
                right: '-22px',
                width: '15px',
                height: '15px',
                cursor: 'pointer',
                display: isSmall ? 'none' : 'block',
              }}
            />
          </Tooltip>
          <Box sx={{ fontSize: 14, display: { xs: 'block', md: 'none' }, position: 'relative', top: -4 }}>
            {intl.formatMessage({ id: `profile.tooltips.phone` })}
          </Box>
        </Box>

        {/* Zip code */}
        <Box sx={{ position: 'relative' }}>
          <TextField
            sx={{
              '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
            }}
            error={
              (formik.touched.zipcode && Boolean(formik.errors.zipcode)) ||
              (choseToStay && keysWithChanges.includes('zipcode'))
            }
            name="zipcode"
            type="text"
            variant="filled"
            fullWidth
            helperText={
              formik.touched.zipcode &&
              formik.errors.zipcode &&
              intl.formatMessage({ id: formik.errors.zipcode }, { amount: 5 })
            }
            autoComplete="off"
            label={intl.formatMessage({ id: 'forms.onboarding.zipcode' })}
            value={formik.values.zipcode}
            onChange={(e) => {
              formik.handleChange(e);
              handleDiffs('zipcode', e);
            }}
            onKeyDown={preventSubmitOnEnter}
            onBlur={formik.handleBlur}
            InputProps={{
              inputComponent: ZipcodeInput,
            }}
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.zipcode' }),
            }}
          />
          <Tooltip
            enterTouchDelay={0}
            title={
              <Fragment>
                <span style={{ fontSize: 14 }}>{intl.formatMessage({ id: `profile.tooltips.zipcode` })}</span>
              </Fragment>
            }
            placement="top"
            arrow
          >
            <img
              alt={intl.formatMessage(
                { id: 'profile.infoAlt' },
                { field: intl.formatMessage({ id: 'forms.onboarding.zipcode' }) }
              )}
              src={info}
              style={{
                position: 'absolute',
                top: '14px',
                right: '-22px',
                width: '15px',
                height: '15px',
                cursor: 'pointer',
                display: isSmall ? 'none' : 'block',
              }}
            />
          </Tooltip>
          <Box sx={{ fontSize: 14, display: { xs: 'block', md: 'none' }, position: 'relative', top: -4 }}>
            {intl.formatMessage({ id: `profile.tooltips.zipcode` })}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: { xs: 3, md: 0 }, mb: { xs: 1, md: 0 } }}>
        <Button color="secondary" disabled={submitting || savingDegree} size="large" type="submit" variant="contained">
          {submitting || savingDegree ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
            </div>
          ) : (
            submitLabel
          )}
        </Button>
      </Box>
    </form>
  );
}

ContactForm.propTypes = {
  choseToStay: PropTypes.bool,
  classNames: PropTypes.string,
  valueChanged: PropTypes.func,
  submitCallback: PropTypes.func,
  submitLabel: PropTypes.string,
  state: PropTypes.string,
  validZipCodes: PropTypes.arrayOf(PropTypes.string),
  initialValues: PropTypes.object,
};

export default Profile;

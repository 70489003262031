import DOMPurify from 'dompurify';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  useMediaQuery,
  type Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
/* i18n Dependencies */
import { useIntl } from 'react-intl';

interface JobInvitationDialogProps {
  open: boolean;
  handleClose: () => void;
  markdownContent: string;
}

export default function JobInvitationDialog(props: JobInvitationDialogProps) {
  const intl = useIntl();
  const sanitizer = DOMPurify.sanitize;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      keepMounted={false}
      open={props.open}
      onClose={props.handleClose}
      PaperProps={{
        sx: {
          paddingX: { xs: 2, md: 2 },
          pt: 3,
          pb: 3,
        },
      }}
      sx={{ zIndex: 99999999999999 }}
    >
      <IconButton
        aria-label="close"
        onClick={props.handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: { xs: 20, md: 12 },
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Card variant="outlined">
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#F5F5F5',
              py: 5,
            }}
          >
            <Box>
              <img
                src="/images/AR-Launch-Logo-Green-Small.png"
                alt="Launch logo"
                style={{ height: '60px', border: 0, marginBottom: 20 }}
              />
            </Box>
            <Box
              sx={{
                maxWidth: 580,
                borderRadius: '3px',
                backgroundColor: 'white',
                p: 4.5,
                textAlign: 'left',
              }}
              dangerouslySetInnerHTML={{ __html: sanitizer(props.markdownContent) }}
            />
          </Box>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button id="edit-template-button" variant="outlined" onClick={props.handleClose}>
          {intl.formatMessage({ id: 'layout.hamburger.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateNotificationRead } from 'lib/API/notifications';

export const useReadNotification = (id: string, read = true) => {
  const queryClient = useQueryClient();
  const queryKey = ['/users/me/notifications/'];

  return useMutation({
    mutationFn: () => updateNotificationRead({ read }, { customPath: `/users/me/notifications/${id}` }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

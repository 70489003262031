import { createSlice } from '@reduxjs/toolkit';

export const experienceSlice = createSlice({
  name: 'experience',
  initialState: {
    skills: [],
    industry: {
      naics2_code: '',
    },
    form: {
      soc_code: '',
      soc_title: '',
      soc_query: '',
      naics_code: '',
      naics_title: '',
      naics_query: '',
      years_experience: '',
      education_level: '',
      zipcode: '',
      banner_text: {
        recentJob: {
          title: '',
          start_date: '',
          month: -1,
          year: 0,
        },
        educationLevel: '',
      },
    },
    has_resume: false,
    loading: true,
    reload_pending: false,
    open_contact_info: false,
  },
  reducers: {
    setExperienceSkills: (state, action) => {
      state.skills = action.payload;
    },
    setExperienceIndustry: (state, action) => {
      state.industry = action.payload;
    },
    setZipcode: (state, action) => {
      state.form = { ...state.form, zipcode: action.payload };
    },
    setExperienceForm: (state, action) => {
      state.form = { ...state.form, ...action.payload };
    },
    setExperienceHasResume: (state, action) => {
      state.has_resume = action.payload;
    },
    setSkillsExperienceLoading: (state, action) => {
      state.loading = action.payload;
    },
    setReloadPending: (state, action) => {
      state.reload_pending = action.payload;
    },
    setOpenContactInfo: (state, action) => {
      state.open_contact_info = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setExperienceSkills,
  setExperienceIndustry,
  setExperienceForm,
  setZipcode,
  setExperienceHasResume,
  setSkillsExperienceLoading,
  setReloadPending,
  setOpenContactInfo,
} = experienceSlice.actions;

export default experienceSlice.reducer;

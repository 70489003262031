import { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { pathOr } from 'ramda';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

const CareerVideo = ({ item, isPlaying, onVideoPlay }) => {
  const intl = useIntl();
  const [reportedQuartiles, setReportedQuartiles] = useState([]);

  const title = `title_${intl.locale}` in item ? item[`title_${intl.locale}`] : item.title;

  let provider = 'edgefactor';
  if (!item.videos.edgefactor || item.videos.edgefactor.length === 0) {
    provider = 'careeronestop';
  }

  // EdgeFactor will not work on localhost because of CORS configuration on their end
  // Force careeronestop when running on craco dev server
  if (process.env.NODE_ENV !== 'production') {
    provider = 'careeronestop';
  }

  const url = pathOr(item?.video, ['videos', provider, 0, 'video'], item);
  let thumbnail = item.videos?.[provider]?.[0].thumbnail;
  const sharedAttributes = {
    crossOrigin: 'anonymous',
    title,
  };

  let config = {};
  if (url && url.length > 0) {
    const sub_tracks = [];
    if (provider === 'careeronestop') {
      const base_url = url.substring(0, url.length - 4);
      const caption_url = base_url.replace('OccVids/OccupationVideos', 'CaptionFiles');
      sub_tracks.push(
        ...[
          ['en', '.vtt'],
          ['es', '_es.vtt'],
        ].map(([lang, ext]) => ({
          kind: 'subtitles',
          src: `${caption_url}${ext}`,
          srcLang: lang,
          label: lang === 'en' ? 'English' : 'Español',
          default: intl.locale === lang,
        }))
      );

      thumbnail = `${base_url}.jpg`;

      config = {
        attributes: { ...sharedAttributes, poster: thumbnail, preload: 'none' },
        tracks: sub_tracks,
      };
    } else if (provider === 'edgefactor') {
      const asset_url = url.replace('streaming', 'asset');
      sub_tracks.push(
        ...[
          ['en', 'en-US'],
          ['es', 'es-MX'],
        ].map(([lang, ext]) => ({
          kind: 'subtitles',
          src: `${asset_url}/caption/${ext}`,
          srcLang: lang,
          label: lang === 'en' ? 'English' : 'Español',
          default: intl.locale === lang,
        }))
      );

      config = {
        attributes: { ...sharedAttributes, disablePictureInPicture: true },
        tracks: sub_tracks,
        forceHLS: true,
        forceVideo: true,
        hlsVersion: '1.5.17',
        hlsOptions: {
          maxMaxBufferLength: 1,
        },
      };
    }
  }

  const trackEvent = (event, data = {}) => {
    window.dataLayer.push({
      event,
      'career-video-soc': item.soc,
      'career-video-title': item.title,
      'career-video-provider': provider,
      ...data,
    });
  };

  const handleReady = (player) => {
    // Turn on captions by default based on user's locale
    if (player.getInternalPlayer()?.textTracks) {
      const textTracks = player.getInternalPlayer().textTracks;

      for (const track of textTracks) {
        if ((track.kind === 'subtitles' || track.kind === 'captions') && track.language === intl.locale) {
          track.mode = 'showing';
          break;
        }
      }
    }
  };

  return url ? (
    <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
      <ReactPlayer
        config={{
          file: { ...config },
        }}
        playsinline
        url={url}
        controls={true}
        id={`career_video_${item.soc}`}
        playing={isPlaying}
        light={
          thumbnail ? (
            <Box component="img" src={thumbnail} width="100%" height="100%" alt={title} sx={{ objectFit: 'cover' }} />
          ) : (
            <Box width="100%" height="100%" sx={{ backgroundColor: 'black' }} />
          )
        }
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0 }}
        onStart={() => {
          trackEvent('click-video-play-careers');
          onVideoPlay();
        }}
        onPause={() => {
          trackEvent('click-video-pause-careers');
        }}
        onPlay={() => {
          trackEvent('click-video-unpause-careers');
          onVideoPlay();
        }}
        onClickPreview={() => {
          trackEvent('click-video-play-careers');
          onVideoPlay();
        }}
        onProgress={({ playedSeconds, played }) => {
          const playedDuration = Math.floor(playedSeconds);
          const playedPercentage = Math.floor(played * 100);
          const quartile = getQuartile(playedPercentage);

          if (playedDuration > 0 && quartile > 0 && !reportedQuartiles.includes(quartile)) {
            trackEvent(`video-duration-careers-${quartile}`, {
              'career-video-duration': playedDuration,
              'career-video-progress': playedPercentage,
              'career-video-quartile': quartile,
            });
            setReportedQuartiles(reportedQuartiles.concat(quartile));
          }
        }}
        onReady={handleReady}
      />
    </Box>
  ) : null;
};

function getQuartile(number) {
  if (number < 25) {
    return 0;
  } else if (number < 50) {
    return 25;
  } else if (number < 75) {
    return 50;
  } else if (number < 100) {
    return 75;
  } else {
    return 100;
  }
}

CareerVideo.propTypes = {
  item: PropTypes.object.isRequired,
  isPlaying: PropTypes.bool,
  onVideoPlay: PropTypes.func,
};

export default CareerVideo;

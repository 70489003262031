import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { getMailingAddress, getBankingInfo } from 'lib/API';

/* Redux */
import { useSelector } from 'react-redux';

/* Material UI and other UI Dependencies */
import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Slide,
  useMediaQuery,
  IconButton,
  Divider,
} from '@mui/material';
import DepositEnrollCard from 'components/DepositEnrollCard';
import DepositInfoCard from 'components/DepositInfoCard';
import DebitCardCard from 'components/DebitCardCard';
import PaymentMethodAlert from 'components/PaymentMethodAlert';
import MailingAddressForm from 'components/forms/MailingAddressForm';
import MailingAddressCard from 'components/MailingAddressCard';
import DirectDepositEnrollForm from 'components/forms/DirectDepositEnrollForm';
import CloseIcon from '@mui/icons-material/Close';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';

import TimeMe from 'timeme.js';

const FileClaim = () => {
  const containerRef = useRef(null);

  const [mailingAddressData, setMailingAddressData] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [paymentInfoData, setPaymentInfoData] = useState();
  const [isFetchingMailing, setIsFetchingMailing] = useState(true);
  const [isFetchingBanking, setIsFetchingBanking] = useState(true);
  const [mailDialogOpen, setMailDialogOpen] = useState(false);
  const [bankingDialogOpen, setBankingDialogOpen] = useState(false);
  const [showEnroll, setShowEnroll] = useState(false);
  const [addressConfirmed, setAddressConfirmed] = useState(false);
  const [paymentMethodConfirmed, setPaymentMethodConfirmed] = useState(false);

  const intl = useIntl();
  const isSmall = useMediaQuery('(max-width:767px)');
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const { selectedClaim } = useSelector((state) => state.ui);
  let history = useHistory();

  useEffect(() => {
    if (!selectedClaim.weekEnd) {
      history.push(`${urlPrefix}/ui-dashboard`);
    } else {
      getMailingAddress().then((response) => {
        setMailingAddressData(response.data);
        setIsFetchingMailing(false);
      });
      getBankingInfo().then((response) => {
        setPaymentInfoData(response.data);
        setIsFetchingBanking(false);
      });
    }
  }, [selectedClaim]);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'fileClaim.title' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('UI_FILE_A_CLAIM');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  if (isFetchingMailing || isFetchingBanking) {
    return (
      <Box mt={6} mb={2.5} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 6, mb: 2.5 }}>
        <Typography variant="h1" fontWeight="bold">
          <AssignmentTwoToneIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
          {intl.formatMessage({ id: 'fileClaim.title' })}
        </Typography>
        <Typography variant="body2">{intl.formatMessage({ id: 'fileClaim.subtitle' })}</Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container gap={3}>
          <Grid item xs={12} sm={5} lg={4} sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
            {mailingAddressData && (
              <>
                <MailingAddressCard mailingAddressData={mailingAddressData} callBack={() => setMailDialogOpen(true)} />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={addressConfirmed}
                      onChange={(e) => {
                        setAddressConfirmed(e.currentTarget.checked);
                      }}
                    />
                  }
                  label={intl.formatMessage({ id: 'fileClaim.confirmAddress' })}
                  sx={{ mt: 1 }}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={5} lg={4} sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
            <Card
              elevation={3}
              sx={{ minHeight: 188, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <CardContent sx={{ px: 3, pt: 3 }}>
                <Typography variant="h4" component="h2" sx={{ pb: 1, color: 'secondary.dark' }}>
                  {intl.formatMessage({ id: 'fileClaim.currentPaymentMethod' })}
                </Typography>
                {paymentInfoData?.current_payment === 'DD' ? (
                  <>
                    <Typography variant="body1">{paymentInfoData.account_type}</Typography>
                    <Typography variant="body1">
                      {intl.formatMessage({ id: 'fileClaim.accountNumber' })}:*{paymentInfoData.account_number}
                    </Typography>
                  </>
                ) : (
                  <Typography flex={2} variant="body1">
                    {intl.formatMessage({ id: 'fileClaim.debit' })}
                  </Typography>
                )}
              </CardContent>
              <CardActions sx={{ px: 3, pb: 3 }}>
                <Button variant="contained" size="small" onClick={() => setBankingDialogOpen(true)}>
                  {intl.formatMessage({ id: 'fileClaim.buttons.edit' })}
                </Button>
              </CardActions>
            </Card>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={paymentMethodConfirmed}
                  onChange={(e) => {
                    setPaymentMethodConfirmed(e.currentTarget.checked);
                  }}
                />
              }
              label={intl.formatMessage({ id: 'fileClaim.confirmPayment' })}
              sx={{ mt: 1 }}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2.5, mb: 4 }}>
          <Button
            type="button"
            onClick={() => history.push(`${urlPrefix}/ui-dashboard`)}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            {intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}
          </Button>
          <Button
            type="button"
            onClick={() => history.push(`${urlPrefix}/continued-claim`)}
            variant="contained"
            disabled={!addressConfirmed || !paymentMethodConfirmed}
          >
            {intl.formatMessage({ id: 'fileClaim.buttons.proceed' })}
          </Button>
        </Box>
      </Box>
      <Dialog open={mailDialogOpen} onClose={() => setMailDialogOpen(false)}>
        <DialogTitle>{intl.formatMessage({ id: 'fileClaim.mailModal.title' })}</DialogTitle>
        <DialogContent>
          <MailingAddressForm
            mailingAddressData={mailingAddressData}
            setMailingAddressData={setMailingAddressData}
            submitting={submitting}
            setSubmitting={setSubmitting}
            setOpen={setMailDialogOpen}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={bankingDialogOpen} onClose={() => setBankingDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle variant="h4" component="h2">
          {intl.formatMessage({ id: 'fileClaim.bankingModal.title' })}
          <IconButton
            aria-label="close"
            onClick={() => setBankingDialogOpen(false)}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent ref={containerRef} sx={{ position: 'relative' }}>
          {(!showEnroll || !isSmall) && <PaymentMethodAlert />}
          <Box mt={3} mb={2}>
            <Grid container spacing={2.5}>
              <Grid item xs={0} md={1} />
              <Grid item xs={12} md={11}>
                <Typography variant="h5">
                  {intl.formatMessage({ id: 'fileClaim.bankingModal.paymentDetails' })}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2.5} sx={{ pt: 2 }}>
              <Grid item xs={0} md={1} />
              <Grid item xs={12} md={5}>
                <DebitCardCard paymentInfoData={paymentInfoData} />
              </Grid>
              <Grid item xs={12} md={5}>
                {paymentInfoData?.current_payment === 'DD' ? (
                  <DepositInfoCard
                    setPaymentInfoData={setPaymentInfoData}
                    paymentInfoData={paymentInfoData}
                    setShowEnroll={setShowEnroll}
                  />
                ) : (
                  <DepositEnrollCard paymentInfoData={paymentInfoData} setShowEnroll={setShowEnroll} />
                )}
              </Grid>
            </Grid>
          </Box>
          <Slide in={showEnroll} container={containerRef.current} direction="left" mountOnEnter unmountOnExit>
            <Box
              sx={{
                bgcolor: 'white',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                pt: 1,
                px: 3,
                boxSizing: 'border-box',
              }}
            >
              <DirectDepositEnrollForm
                setPaymentInfoData={setPaymentInfoData}
                submitting={submitting}
                setSubmitting={setSubmitting}
                setOpen={setShowEnroll}
              />
            </Box>
          </Slide>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default FileClaim;

import { useQueries } from '@tanstack/react-query';
import { apiRequest } from 'shared/API';

export const fetchData = async ({ queryKey }: { queryKey: string[] }) => {
  const url = queryKey[0];
  return apiRequest('GET', url);
};

interface Skill {
  id: string;
  name: string;
}

interface Field {
  soc: string;
  title: string;
}
export interface Experience {
  description: string;
  employer_provider: string;
  skills: Skill[];
  start_date: string;
  end_date: string | null;
  type: 'employment' | 'apprenticeship' | 'internship' | 'degree';
  fields: Field[];
  field: Field;
}

interface Header {
  name: string;
  email: string;
  phone_number?: string;
  location: string;
  url?: string;
}

const VALID_WORK_EXPERIENCE_TYPES: Experience['type'][] = ['employment', 'apprenticeship', 'internship'];

export type Section =
  | { type: 'summary'; name: string; items: string[] }
  | { type: 'skills'; name: string; items: Skill[] }
  | { type: 'work_experience' | 'others'; name: string; items: Experience[] };

export interface UseResumeDataQuery {
  data: {
    header: Header;
    sections: Section[];
  };
  isLoading: boolean;
}

function useResumeDataQuery(): UseResumeDataQuery {
  const results = useQueries({
    queries: [
      { queryKey: ['/users/me'], queryFn: fetchData },
      { queryKey: ['/users/me/experience/'], queryFn: fetchData },
      { queryKey: ['/users/me/location/'], queryFn: fetchData },
      { queryKey: ['/cities/'], queryFn: fetchData },
    ],
  });

  const isLoading = results.some((result) => result.isLoading);

  const header: Header =
    results[0].data && results[2].data && results[3].data
      ? {
          name: `${results[0].data.first_name} ${results[0].data.last_name}`,
          email: results[0].data.email,
          phone_number: results[0].data.phone_number,
          location: (() => {
            const { city, state_id } = results[3].data.find(
              (city: { city: string; id: string; state_id: string }) => city.id === results[2].data.current_city
            );

            return `${city}, ${state_id}`;
          })(),
          url: '',
        }
      : { name: '', email: '', phone_number: '', location: '' };

  const work_experience: Experience[] = results[1].data.job_experience
    .filter((experience: Experience) => VALID_WORK_EXPERIENCE_TYPES.includes(experience.type))
    .map((experience: Experience) => ({ ...experience, field: experience.fields[0] }));

  const others_experience: Experience[] = results[1].data.job_experience
    .filter((experience: Experience) => !VALID_WORK_EXPERIENCE_TYPES.includes(experience.type))
    .map((experience: Experience) => ({ ...experience, field: experience.fields[0] }));

  const sections: Section[] = [
    {
      name: '',
      type: 'summary',
      items: [],
    },
    {
      name: '',
      type: 'skills',
      items: results[1].data.job_experience[0].skills.slice(0, 5) || [],
    },
    {
      name: '',
      type: 'work_experience',
      items: work_experience,
    },
    {
      name: '',
      type: 'others',
      items: others_experience,
    },
  ];

  return {
    data: {
      header,
      sections,
    },
    isLoading,
  };
}

export default useResumeDataQuery;

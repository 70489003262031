export const formatTimestamp = (date) => {
  return (
    date.getUTCFullYear() +
    ('0' + (date.getUTCMonth() + 1)).slice(-2) +
    ('0' + date.getUTCDate()).slice(-2) +
    ('0' + date.getUTCHours()).slice(-2) +
    ('0' + date.getUTCMinutes()).slice(-2)
  );
};

export const passwordTests = {
  lowercase: /.*[a-z]+.*/,
  uppercase: /.*[A-Z]+.*/,
  // Special character list from:
  // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
  special: /.*[\^$*.\[\]{}\(\)?"!@#%&/\\,><\':;|_~`=+\-]+.*/, //eslint-disable-line no-useless-escape
  digit: /.*[\d]+.*/,
  length: 8,
  nospaces: /^\S+$/,
};

/**
 * Covert bytes to a larger size
 * Usage: In the upload file field
 *
 * @param {*} bytes
 * @param {*} decimals
 */
export const bytesToSize = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Formats a given phone number string into a standard format: (XXX) XXX-XXXX.
 *
 * @param {string} phoneNumberString - The phone number input as a string
 * @returns {string|null} - The formatted phone number as a string if valid, or `null` if the input does not match the expected format.
 *
 * @example
 * formatPhoneNumber("1234567890"); // "(123) 456-7890"
 * formatPhoneNumber("123456"); // null
 */
export function formatPhoneNumber(phoneNumberString) {
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
}
